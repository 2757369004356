<div class="grid nested-grid">
  <div class="col-12 xl:col-4">
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <p-button
            label="{{ 'dashboard.newAnimal' | transloco }}"
            styleClass="mb-2"
            (onClick)="toggleAddDialog()"
          >
            <i class="pi pi-plus mr-1"></i>
          </p-button>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <h4>{{ 'dashboard.workload' | transloco }}</h4>
          <div class="flex align-items-center justify-content-center">
            <p-chart type="doughnut" [data]="data" [options]="options"></p-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 xl:col-8">
    <div class="card p-1 pt-3">
      <h3 class="p-1 text-center">{{ 'dashboard.newAnimals.headline' | transloco }}</h3>
      <p-dataView [value]="newAnimals" [paginator]="true" [rows]="5" sortField="lastModified">
        <ng-template pTemplate="header" class="p-1">
          <div class="flex flex-column md:flex-row md:justify-content-between font-semibold">
            <div class="flex-1 flex align-items-center justify-content-start w-7rem max-w-7rem"></div>
            <div
              class="flex-1 flex align-items-center justify-content-start p-2">{{ 'dashboard.newAnimals.name' | transloco }}
            </div>
            <div
              class="flex-1 flex align-items-center justify-content-start p-2 max-w-7rem">{{ 'dashboard.newAnimals.category' | transloco }}
            </div>
            <div
              class="flex-1 flex align-items-center justify-content-start p-2">{{ 'dashboard.newAnimals.whereabout' | transloco }}
            </div>
            <div
              class="flex-1 flex align-items-center justify-content-start p-2">{{ 'dashboard.newAnimals.updatedAt' | transloco }}
            </div>
            <div class="flex-1 flex align-items-center justify-content-start"></div>
          </div>
        </ng-template>
        <ng-template pTemplate="listItem" let-animal>
          <div class="animal-item flex flex-row w-full align-items-stretch">
            <div class="flex flex-shrink-0 min-w-8rem w-8rem min-h-8rem h-8rem p-1 relative">
              <app-animal-image-container [animal]="animal" [preview]="true"></app-animal-image-container>
            </div>
            <div class="flex-1 flex align-items-center justify-content-start p-2">{{ animal?.displayName }}</div>
            <div class="flex-1 flex align-items-center justify-content-start p-0 max-w-7rem">
              <app-animal-current-category [animal]="animal"></app-animal-current-category>
            </div>
            <div class="flex-1 flex align-items-center justify-content-start p-2">
              <span *ngIf="whereabouts?.get(animal.id)?.type">
                {{ ('dashboard.newAnimals.whereabouts.' + whereabouts?.get(animal.id)?.type) | transloco }}
              </span>
            </div>
            <div class="flex-1 flex align-items-center justify-content-start">
              {{ animal.updatedAt | date: getDateFormat() }}
            </div>
            <div class="flex-1 flex align-items-center justify-content-start">
              <p-button label="{{'dashboard.newAnimals.details' | transloco}}" class="p-0 m-1"
                        [routerLink]="['/tiere', animal.id]" icon="pi pi-eye"/>
            </div>
          </div>
        </ng-template>
      </p-dataView>
    </div>
  </div>
</div>
<ng-container *ngIf="addDialogVisible">
  <app-animal-add-dialog></app-animal-add-dialog>
</ng-container>