import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AnimalRepository } from "./states/animal.repository";
import { AnimalService } from "./services/animal.service";
import { Subscription } from "rxjs";
import { AnimalModel } from "./models/animal.model";
import { AnimalAddService } from "./services/animal-add.service";
import { AnimalFilter } from "./animal-filters/animal-filter.model";
import { DataView, DataViewPaginatorState } from 'primeng/dataview';

@Component({
  selector: 'app-animal-overview',
  templateUrl: './animal-overview.component.html',
  styleUrls: ['./animal-overview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnimalOverviewComponent implements OnInit, OnDestroy {
  @ViewChild('dv') dataView!: DataView;
  alive: boolean = true;
  animals: AnimalModel[] = [];
  filteredAnimals: AnimalModel[] = [];
  selectedFilterSubscription: Subscription;
  layout: "list" | "grid" = 'grid';
  categoryFilters: AnimalFilter[] = [];
  searchTerm: string = '';
  rowCount: number = 3;
  addDialogVisible: boolean = false;

  constructor(
    private _animalRepository: AnimalRepository,
    private _animalAddService: AnimalAddService,
    public _animalService: AnimalService
  ) {
    this.selectedFilterSubscription = this._animalService.selectedFilters$.subscribe(() => {
      this.resetPage();
      this.filterAnimals();
    });
  }

  ngOnInit(): void {
    this.searchTerm = this._animalService.searchTerm;

    this._animalAddService.addDialogVisible.subscribe((openModal) => {
      this.addDialogVisible = openModal;
    });

    this._animalRepository.getAll().subscribe((animals: AnimalModel[]) => {
      this.animals = animals;
      this.categoryFilters = this._animalService.getFilterCategories();
      this.filterAnimals();
    });
  }

  handleSearch(event: Event): void {
    if (!event.target) {
      return;
    }
  
    this.searchTerm = (event.target as HTMLInputElement).value;
    this._animalService.searchTerm = this.searchTerm;

    this.resetPage();
  
    this.filterAnimals();
  }

  filterAnimals(): void {
    const selectedFilters = this._animalService.getSelectedFilters();

    if (selectedFilters.length > 0) {
      this.filteredAnimals = this.animals.filter(animal =>
        selectedFilters.some(filter => animal.category?.toLowerCase() === filter.id.toLowerCase())
      );
    } else {
      this.filteredAnimals = [...this.animals];
    }

    if (this.searchTerm !== '') {
      this.searchTerm = this.searchTerm.toLowerCase();
      this.filteredAnimals = this.filteredAnimals.filter(animal =>
        animal.name?.toLowerCase().includes(this.searchTerm) ||
        animal.bookNumber?.toLowerCase().includes(this.searchTerm) ||
        animal.oldBookNumber?.toLowerCase().includes(this.searchTerm)
      );
    }

    this.filteredAnimals.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    this._animalService.setSelectedFilters(selectedFilters);
  }

  changeLayout(event: any): void {
    switch (event.layout) {
      case 'grid':
        this.rowCount = 3;
        break;
      case 'list':
        this.rowCount = 10;
        break;
    }
  }

  onPageChange(event: DataViewPaginatorState): void {
    const page = Math.floor((event.first ?? 0) / (event.rows ?? this.rowCount));
    this._animalService.currentPage = page;
  }

  resetPage(): void {
    if (!this.dataView) {
      return;
    }
    this._animalService.currentPage = 0;
    this.dataView.first = 0;
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.selectedFilterSubscription.unsubscribe();
  }

  toggleAddDialog(): void {
    this._animalAddService.toggleAddDialog(true);
  }
}
