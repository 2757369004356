export enum AnimalCategory {
  DOG = 'dog',
  CAT = 'cat',
  HORSE = 'horse',
  SMALL_ANIMAL = 'small_animal',
  REPTILE = 'reptile',
  WILD_ANIMAL = 'wild_animal',
  FARM_ANIMAL = 'farm_animal',
  BIRD = 'bird',
}
