import { AnimalImageModel } from './../../../modules/animals/models/animal-image.model';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { AnimalModel } from 'src/app/modules/animals/models/animal.model';
import { AnimalImagesRepository } from 'src/app/modules/animals/states/animal-images.repository';
import {AnimalCategory} from "../../../modules/animals/models/animal-category.enum";

@Component({
  selector: 'app-animal-image-container',
  templateUrl: './animal-image-container.component.html',
  styleUrls: ['./animal-image-container.component.scss']
})
export class AnimalImageContainerComponent implements OnInit, OnChanges {
  @Input() id?: number | undefined;
  @Input() variant?: string = '';
  @Input() preview?: boolean = false;
  @Input() animal: AnimalModel | undefined;
  @Input() iconBadge: boolean = true;

  imageUrl: string | null | undefined = '';
  isLoading: boolean = false;

  constructor(
    private animalImageRepository: AnimalImagesRepository,
    private cdr: ChangeDetectorRef,
    private _animalImagesRepository: AnimalImagesRepository
  ) { }


  ngOnInit(): void {
    if (this.animal) {
      this._animalImagesRepository.getImages(this.animal.id).pipe(take(1)).subscribe(() => {});
    }
    if (!this.preview) {
      this.getAnimalImage();
    } else {
      this.getPreviewImage();
    }
  }


  ngOnChanges(): void {
    if (!this.preview) {
      this.getAnimalImage();
    } else {
      this.getPreviewImage();
    }
  }

  getAnimalImage() {
    if (!this.id || !this.variant) {
      return;
    }
    this.animalImageRepository.getImageById(this.id).pipe(take(1)).subscribe((image: AnimalImageModel | null) => {
      if (image && this.variant && this.variant.length && image.variants[this.variant]) {
        if (!image.variants[this.variant].base64 && !this.isLoading) {
          this.isLoading = true;
          if (this.id) {
            this.animalImageRepository.loadVariantBase64(this.id, this.variant).pipe(take(1)).subscribe(updatedImage => {
              if (this.variant) {
                this.imageUrl = updatedImage?.variants[this.variant].base64;
                this.isLoading = false;
              }
            });
          }
        } else {
          this.imageUrl = image.variants[this.variant].base64;
        }
      } else {
        console.error('Variant not found or image is null:', this.variant, image);
      }
      this.cdr.detectChanges();
    });
  }

  getPreviewImage() {
    if (this.animal) {
      const previewImage = this.animal.previewImage;
      if (previewImage && !previewImage.variants['thumbnail'].base64 ) {
        this.isLoading = true;
        if (previewImage.id) {
          this.animalImageRepository.loadVariantBase64(previewImage.id, 'thumbnail').pipe(take(1)).subscribe(updatedImage => {
            if (updatedImage) {
              this.imageUrl = updatedImage?.variants['thumbnail'].base64;
              this.isLoading = false;
            }
          });
        }
      } else {
        this.imageUrl = previewImage?.variants['app-preview'].base64;
      }
      this.cdr.detectChanges();
    }
  }

  getFallbackImage(): string {
    const category = this.animal?.category?.toLowerCase();
    switch (category) {
      case AnimalCategory.BIRD:
        return './assets/images/animals/default_bird.png';
      case AnimalCategory.CAT:
        return './assets/images/animals/default_cat.png';
      case AnimalCategory.DOG:
        return './assets/images/animals/default_dog.png';
        case AnimalCategory.FARM_ANIMAL:
        return './assets/images/animals/default_pig.png';
      case AnimalCategory.REPTILE:
        return './assets/images/animals/default_reptile.png';
      case AnimalCategory.SMALL_ANIMAL:
        return './assets/images/animals/default_small_animal.png';
      case AnimalCategory.WILD_ANIMAL:
        return './assets/images/animals/default_wild_animal.png';
      case AnimalCategory.HORSE:
        return './assets/images/animals/default_horse.png';
      default:
        return './assets/images/animals/default_dog.png';
    }
  }
}

