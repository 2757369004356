export enum AnimalCharacteristics {
  // FOR ALL
  SENIOR = 'senior',
  CAN_DRIVE_CAR = 'can_drive_car',
  SUITABLE_FOR_BEGINNERS = 'suitable_for_beginners',
  SUITABLE_FOR_FAMILIES = 'suitable_for_families',
  COMPATIBLE_WITH_SAME_SPECIES = 'compatible_with_same_species',
  COMPATIBLE_WITH_SMALL_ANIMALS = 'compatible_with_small_animals',

  NOT_COMPATIBLE_WITH_MEN = 'not_compatible_with_men',
  NOT_COMPATIBLE_WITH_WOMEN = 'not_compatible_with_women',
  COMPATIBLE_WITH_CHILDREN = 'compatible_with_children',
  NEEDS_MEDICATION = 'needs_medication',
  NEEDS_ADJUSTMENT_PERIOD = 'needs_adjustment_period',
  NEEDS_TIME_TO_BUILD_TRUST = 'needs_time_to_build_trust',
  SENSITIVE = 'sensitive',
  SHY = 'shy',
  DOESNT_LIKE_TO_BE_TOUCHED = 'doesnt_like_to_be_touched',
  DOESNT_LIKE_TO_BE_PICKED_UP = 'doesnt_like_to_be_picked_up',
  CURIOUS = 'curious',
  HAND_TAMED = 'hand_tamed',
  LEASH_TRAINED = 'leash_trained',
  GOOD_SOCIAL_BEHAVIOR = 'good_social_behavior',
  CAN_OPEN_DOORS = 'can_open_doors',
  INSECURE = 'insecure',
  ANXIOUS = 'anxious',
  ENERGETIC = 'energetic',
  HUMAN_ORIENTED = 'human_oriented',
  MIXED_GROUP = 'mixed_group',
  BINDS_TO_ONE_PERSON = 'binds_to_one_person',
  
  
  // DOG
  CAN_STAY_ALONE = 'can_stay_alone',
  LIKES_CAT = 'likes_cat',
  CUDDLER = 'cuddler',
  ONLY_FOR_PEOPLE_WITH_DOG_EXPERIENCE = 'only_for_people_with_dog_experience',
  ONLY_FOR_PEOPLE_WITH_BREED_EXPERIENCE = 'only_for_people_with_breed_experience',
  RACE_RELATED_HUNTING_INSTINCT = 'race_related_hunting_instinct',

  GOOD_WATCHDOG = 'good_watchdog',
  REQUIRES_FIRM_TRAINING = 'requires_firm_training',
  OBEDIENT = 'obedient',
  QUIET = 'quiet',
  AFFECTIONATE = 'affectionate',
  DEMANDING = 'demanding',
  PLAYFUL = 'playful',
  JEALOUS = 'jealous',
  UNCOMPLICATED = 'uncomplicated',
  STUBBORN = 'stubborn',
  BALANCED = 'balanced',
  SUBMISSIVE = 'submissive',
  DOMINANT = 'dominant',
  LOVES_COMMOTION = 'loves_commotion',
  ATTENTIVE = 'attentive',
  NEEDS_EXERCISE = 'needs_exercise',
  DOESNT_LIKE_BEING_ALONE = 'doesnt_like_being_alone',
  NEEDS_LOTS_OF_ACTIVITY = 'needs_lots_of_activity',
  LOVES_SWIMMING = 'loves_swimming',
  WELL_TRAINED = 'well_trained',
  KNOWS_BASIC_COMMANDS = 'knows_basic_commands',
  NEEDS_TRAINING = 'needs_training',
  NEEDS_LOTS_OF_AFFECTION = 'needs_lots_of_affection',
  PROTECTIVE_INSTINCT = 'protective_instinct',
  HERDING_INSTINCT = 'herding_instinct',
  LIKES_TO_HUNT = 'likes_to_hunt',
  CLIMBS_OVER_FENCES = 'climbs_over_fences',
  BARKER = 'barker',
  NOT_COMPATIBLE_WITH_SAME_SPECIES = 'not_compatible_with_same_species',
  NOT_COMPATIBLE_WITH_LARGE_DOGS = 'not_compatible_with_large_dogs',
  NOT_COMPATIBLE_WITH_SMALL_DOGS = 'not_compatible_with_small_dogs',
  NOT_COMPATIBLE_WITH_DOGS = 'not_compatible_with_dogs',
  NOT_COMPATIBLE_WITH_BIRDS = 'not_compatible_with_birds',
  NOT_COMPATIBLE_WITH_SMALL_ANIMALS = 'not_compatible_with_small_animals',
  NOT_COMPATIBLE_WITH_CATS = 'not_compatible_with_cats',
  
  
  //CAT
  INDOOR_CAT = 'indoor_cat',
  OUTDOOR_CAT = 'outdoor_cat',
  LIKES_DOGS = 'likes_dogs',
  LONER = 'loner',
  
  GOOD_MOUSER = 'good_mouser',
  INDEPENDENT = 'independent',
  VOCAL = 'vocal',
  LITTER_TRAINED = 'litter_trained',
  
  
  //HORSE
  OPEN_STABLE_KEEPING = 'open_stable_keeping',
  STABLE_KEEPING_WITH_FREEDOM = 'stable_keeping_with_freedom',
  ASSISTENT_HORSE = 'assistant_horse',
  EQUINE_PASSPORT = 'equine_passport',
  RIDING_HORSE = 'riding_horse',
  COMPATIBLE_WITH_DOGS = 'compatible_with_dogs',
  EASY_TO_LOAD = 'easy_to_load',
  COMPATIBLE_WITH_FARRIER = 'compatible_with_farrier',
  
  DRESSAGE_HORSE = 'dressage_horse',
  JUMPER = 'jumper',
  NEEDS_EXPERIENCED_HANDLER = 'needs_experienced_handler',
  MUST_BE_FED_ADDITIONALLY = 'must_be_fed_additionally',
  EASY_KEEPER = 'easy_keeper',
  HARD_KEEPER = 'hard_keeper',
  TRAILER_FRIENDLY = 'trailer_friendly',
  UNBROKEN = 'unbroken',
  LONGED = 'longed',
  SADDLE_BROKEN = 'saddle_broken',
  FULLY_TRAINED = 'fully_trained',
  DRIVING_HORSE = 'driving_horse',
  EASY_MEDICATION_ADMINISTRATION = 'easy_medication_administration',
  GOOD_WITH_VETERINARIAN = 'good_with_veterinarian',
  NERVOUS = 'nervous',
  HIGH_RANKING = 'high_ranking',
  LOW_RANKING = 'low_ranking',
  STRESS_PRONE = 'stress_prone',
  LEAD_MARE = 'lead_mare',
  SECURE_FENCING = 'secure_fencing',
  FAMILIAR_WITH_MACHINERY_VEHICLES_ANIMALS = 'familiar_with_machinery_vehicles_animals',
  GELDING_HERD_MIXED_HERD = 'gelding_herd_mixed_herd',
  TRAINED_FOR_HORSEMAN_SHIP = 'trained_for_horseman_ship',
  HAS_ILLNESS_CUSHING = 'has_illness_cushing',
  HAS_ILLNESS_COLIC = 'has_illness_colic',
  HAS_ILLNESS_STOMACH_ULCER = 'has_illness_stomach_ulcer',
  HAS_ILLNESS_LAMINITIS = 'has_illness_laminitis',

  // Small Animal
  GENTLE = 'gentle',
  LIVELY = 'lively',
  REQUIRES_GROOMING = 'requires_grooming',
  ENCLOSURE_NEEDS_MINIMUM_HEIGHT = 'enclosure_needs_minimum_height',
  ENCLOSURE_NEEDS_PREDATOR_PROTECTION = 'enclosure_needs_predator_protection',
  ENCLOSURE_NEEDS_DIGGING_PROTECTION = 'enclosure_needs_digging_protection',
  ENCLOSURE_NEEDS_MINIMUM_SIZE = 'enclosure_needs_minimum_size',
}

export function getCharacteristicsEnumKey(value: AnimalCharacteristics): string | undefined {
  return Object.keys(AnimalCharacteristics).find((key) => AnimalCharacteristics[key as keyof typeof AnimalCharacteristics] === value);
}