<button (click)="openScanner()" class="p-button p-button-icon-only scanner-button">
  <i class="pi pi-camera"></i>
</button>

<div *ngIf="isScannerOpen" class="scanner-overlay">
  <div class="scanner-dialog">
    <video #videoElement autoplay></video>
    <button class="p-button" (click)="closeScanner()">{{ 'general.close' | transloco }}</button>
  </div>
</div>
