import {createStore} from '@ngneat/elf';
import {
  withEntities,
  withActiveId, updateEntities, addEntities, setEntities,
} from '@ngneat/elf-entities';
import {withRequestsCache, withRequestsStatus} from '@ngneat/elf-requests';
import {Injectable} from "@angular/core";
import {RestService} from 'src/app/shared/services/rest.service';
import {ContactModel} from "../models/contact.model";
import {map, switchMap, take, tap} from "rxjs/operators";
import {Observable, of} from "rxjs";

const storeName = 'contacts';

export const store = createStore(
  {name: storeName},
  withEntities<ContactModel>(),
  withActiveId(),
  withRequestsCache<typeof storeName>(),
  withRequestsStatus<typeof storeName>()
);

@Injectable({providedIn: 'root'})
export class ContactRepository {
  constructor(
    private _restService: RestService
  ) {
    store.reset();
  }

  fetchData() {
    this._restService.httpGet('/contacts')
      .pipe(
        take(1)
      ).subscribe((contacts: ContactModel[]) => {
      this.setAll(contacts);
    });
  }

  getAll(): Observable<ContactModel[]> {
    return this._restService.httpGet('/contacts')
      .pipe(
        tap((contacts: ContactModel[]) => {
          this.setAll(contacts);
        })
      );
  }

  getById(contactId: number | undefined): Observable<ContactModel> {
    if (contactId === null || contactId === undefined) {
      return of({} as ContactModel);
    }
  
    return store.pipe(
      take(1),
      switchMap((state) => {
        if (state.entities[contactId]) {
          return of(state.entities[contactId] as ContactModel);
        } else {
          return this._restService.httpGet('/contacts/' + contactId).pipe(
            tap((contact: ContactModel) => {
              store.update(addEntities(contact));
            })
          );
        }
      })
    );
  }
  
  

  setAll(contacts: ContactModel[]) {
    store.update(setEntities(contacts));
  }

  create(payload: any) {
    return this._restService.httpPost('/contacts', payload)
      .pipe(
        tap((response) => {
          if (response && response.id) {
            store.update(addEntities(response));
          }
        })
      );
  }

  searchContacts(searchTerm: string): Observable<ContactModel[]> {
    return this._restService.httpGet(`/contacts?search=${searchTerm}`);
  }

  update(payload: any, contactId: number | undefined) {
    if (contactId === null || contactId === undefined) {
      return of({} as ContactModel);
    }
    return this._restService.httpPut('/contacts/' + contactId, payload)
      .pipe(
        tap((contact) => {
          if (contact && contact.id) {
            store.update(updateEntities(contactId, contact));
          }
        })
      );
  }
}