import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AnimalService} from "../services/animal.service";
import {AnimalFilter} from "./animal-filter.model";
import { takeWhile } from 'rxjs';


@Component({
  selector: 'app-animal-filters',
  templateUrl: './animal-filters.component.html',
  styleUrls: ['./animal-filters.component.scss']
})
export class AnimalFiltersComponent implements OnInit, OnDestroy {
  alive: boolean = true;
  typeFilters: AnimalFilter[] = [];
  selectedFilters: string[] = [];
  @Output() searchEvent = new EventEmitter<Event>();
  searchTerm: string = '';

  constructor(
    private _animalService: AnimalService) {
  }

  ngOnInit(): void {
    this.searchTerm = this._animalService.searchTerm;
    this.typeFilters = this._animalService.getFilterCategories();
    this._animalService.selectedFilters$.pipe(takeWhile(() => this.alive)).subscribe((filters: AnimalFilter[]) => {
      this.selectedFilters = filters.map(f => f.id);
    });
  }

  onFilterSelect(filter: AnimalFilter): void {
    this._animalService.addFilter(filter);
  }

  onFilterDeselect(filter: AnimalFilter): void {
    this._animalService.removeFilter(filter);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}