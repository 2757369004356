import {Injectable} from "@angular/core";
import {forkJoin, Observable, Subject, take} from 'rxjs';
import {AnimalRepository} from "../states/animal.repository";
import {AnimalOriginRepository} from "../states/animal-origin.repository";
import {AnimalOriginModel} from "../models/animal-origin.model";
import {ContactModel} from "../../contact/models/contact.model";
import {Validators} from "@angular/forms";
import { ContactRepository } from '../../contact/states/contact.repository';

@Injectable({
  providedIn: 'root'
})
export class AnimalAddService {
  addDialogVisible = new Subject<boolean>();

  constructor(
    private _animalRepository: AnimalRepository,
    private _animalOriginRepository: AnimalOriginRepository,
    private _contactRepository: ContactRepository
  ) {
  }

  toggleAddDialog(open: boolean) {
    this.addDialogVisible.next(open);
  }

  saveOrigin(
    formGroupValues: any,
    animalId: number,
    contactGiver: ContactModel,
    contactOwner: ContactModel,
    existingGiverContact: boolean,
    existingContactGiverUpdated: boolean,
    existingOwnerContact: boolean,
    existingContactOwnerUpdated: boolean
  ) {
    const originPayload: AnimalOriginModel | any = {
      animal: {
        id: animalId
      },
      competentAuthority: {
        id: formGroupValues.originCompetentAuthority
      },
      givingContact: null,
      owningContact: null,
      dateOfReceipt: formGroupValues.originDateOfReceipt,
      reasonForGiving: formGroupValues.reasonForGiving,
      specialInformation: formGroupValues.originSpecialInformation,
      location: formGroupValues.originLocation,
      otherComments: formGroupValues.originOtherComments,
      conditionOfCare: formGroupValues.originConditionOfCare,
      nutritionalStatus: formGroupValues.originNutritionalStatus,
    };
  
    const updateRequests: Observable<ContactModel>[] = [];
    const requests: Observable<ContactModel>[] = [];
  
    // Handle existing and updated giver contact
    if (existingGiverContact && existingContactGiverUpdated && this.validContact(contactGiver)) {
      updateRequests.push(this._contactRepository.update(contactGiver, contactGiver.id));
    }
  
    // Handle existing and updated owner contact
    if (existingOwnerContact && existingContactOwnerUpdated && this.validContact(contactOwner)) {
      updateRequests.push(this._contactRepository.update(contactOwner, contactOwner.id));
    }
  
    // Handle new giver contact
    if (!existingGiverContact && this.validContact(contactGiver)) {
      const contactGiverPayload = {
        gender: contactGiver.gender,
        firstName: contactGiver.firstName,
        lastName: contactGiver.lastName,
        companyName: contactGiver.companyName,
        zipCode: contactGiver.zipCode,
        city: contactGiver.city,
        street: contactGiver.street,
        phoneNumber: contactGiver.phoneNumber,
        mobilePhoneNumber: contactGiver.mobilePhoneNumber,
        email: contactGiver.email,
        idCardNumber: contactGiver.idCardNumber,
      };
      requests.push(this._contactRepository.create(contactGiverPayload));
    }
  
    // Handle new owner contact
    if (!existingOwnerContact && this.validContact(contactOwner)) {
      const contactOwnerPayload = {
        gender: contactOwner.gender,
        firstName: contactOwner.firstName,
        lastName: contactOwner.lastName,
        companyName: contactOwner.companyName,
        zipCode: contactOwner.zipCode,
        city: contactOwner.city,
        street: contactOwner.street,
        phoneNumber: contactOwner.phoneNumber,
        mobilePhoneNumber: contactOwner.mobilePhoneNumber,
        email: contactOwner.email,
        idCardNumber: contactOwner.idCardNumber,
      };
      requests.push(this._contactRepository.create(contactOwnerPayload));
    }
  
    // Assign IDs for existing contacts that are not updated
    if (existingGiverContact && !existingContactGiverUpdated && this.validContact(contactGiver)) {
      originPayload.givingContact = { id: contactGiver.id };
    }
  
    if (existingOwnerContact && !existingContactOwnerUpdated && this.validContact(contactOwner)) {
      originPayload.owningContact = { id: contactOwner.id };
    }
  
    // Handle updates and creations
    if (updateRequests.length > 0) {
      forkJoin(updateRequests).subscribe((updatedContacts: ContactModel[]) => {
        let updatedGiverId: number | null = null;
        let updatedOwnerId: number | null = null;
  
        updatedContacts.forEach((contact) => {
          if (existingGiverContact && contact.id === contactGiver.id) {
            updatedGiverId = contact.id;
          }
          if (existingOwnerContact && contact.id === contactOwner.id) {
            updatedOwnerId = contact.id;
          }
        });
  
        this.handleContactCreation(requests, originPayload, animalId, updatedGiverId, updatedOwnerId);
      });
    } else {
      this.handleContactCreation(
        requests,
        originPayload,
        animalId,
        existingGiverContact && existingContactGiverUpdated ? contactGiver.id : null,
        existingOwnerContact && existingContactOwnerUpdated ? contactOwner.id : null
      );
    }
  }
  
  private handleContactCreation(
    requests: Observable<ContactModel>[],
    originPayload: AnimalOriginModel,
    animalId: number,
    updatedGiverId: number | null = null,
    updatedOwnerId: number | null = null
  ) {
    if (requests.length > 0) {
      forkJoin(requests).subscribe((responses: ContactModel[]) => {
        responses.forEach((response) => {
          if (response.id) {
            if (!updatedGiverId && this.validContact(response)) {
              originPayload.givingContact = { id: response.id };
            } else if (!updatedOwnerId && this.validContact(response)) {
              originPayload.owningContact = { id: response.id };
            }
          }
        });
  
        if (updatedGiverId) {
          originPayload.givingContact = { id: updatedGiverId };
        }
        if (updatedOwnerId) {
          originPayload.owningContact = { id: updatedOwnerId };
        }
  
        this.createOrigin(originPayload, animalId);
      });
    } else {
      if (updatedGiverId) {
        originPayload.givingContact = { id: updatedGiverId };
      }
      if (updatedOwnerId) {
        originPayload.owningContact = { id: updatedOwnerId };
      }
  
      this.createOrigin(originPayload, animalId);
    }
  }
  
  private createOrigin(originPayload: AnimalOriginModel, animalId: number) {
    this._animalOriginRepository.create(originPayload).subscribe(() => {
      this._animalRepository.addOrigin(animalId, originPayload);
    });
  }
  
  validContact(contact: ContactModel): boolean {
    return !!(contact.firstName || contact.lastName || contact.companyName);
  }
  
  

  togglePersonFields(formGroup: any, $event: any) {
    if ($event.checked === false) {
      formGroup.get('originPersonSalutation')?.setValidators([]);
      formGroup.get('originPersonFirstName')?.setValidators([]);
      formGroup.get('originPersonLastName')?.setValidators([]);
      formGroup.get('originPersonZipCode')?.setValidators([]);
      formGroup.get('originPersonCity')?.setValidators([]);
      formGroup.get('originPersonStreet')?.setValidators([]);
      formGroup.get('originPersonCompanyName')?.setValidators([]);
    } else {
      formGroup.get('originPersonSalutation')?.setValidators([Validators.required]);
      formGroup.get('originPersonFirstName')?.setValidators([Validators.required]);
      formGroup.get('originPersonLastName')?.setValidators([Validators.required]);
      formGroup.get('originPersonZipCode')?.setValidators([Validators.required]);
      formGroup.get('originPersonCity')?.setValidators([Validators.required]);
      formGroup.get('originPersonStreet')?.setValidators([Validators.required]);
      formGroup.get('originPersonCompanyName')?.setValidators([Validators.required]);
    }

    formGroup.get('originPersonSalutation')?.updateValueAndValidity();
    formGroup.get('originPersonFirstName')?.updateValueAndValidity();
    formGroup.get('originPersonLastName')?.updateValueAndValidity();
    formGroup.get('originPersonZipCode')?.updateValueAndValidity();
    formGroup.get('originPersonCity')?.updateValueAndValidity();
    formGroup.get('originPersonStreet')?.updateValueAndValidity();
    formGroup.get('originPersonCompanyName')?.updateValueAndValidity();

    return formGroup;
  }
}