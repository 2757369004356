import { AnimalModel } from 'src/app/modules/animals/models/animal.model';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AnimalAddService} from "../services/animal-add.service";
import {MenuItem} from "primeng/api";
import {AnimalFilter} from "../animal-filters/animal-filter.model";
import {AnimalService} from "../services/animal.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AnimalCategoryModel} from "../models/animal-category.model";
import {AnimalGenderModel} from "../models/animal-gender.model";
import {AnimalCoatTypeModel} from "../models/animal-coat-type.model";
import {AnimalTypeModel} from "../models/animal-type.model";
import {filter, map, take} from "rxjs";
import {CompetentAuthorityModel} from "../../../shared/models/competent-authority.model";
import {PersonTitleModel} from "../../../shared/models/person-title.model";
import {CompetentAuthoritiesService} from "../../../shared/services/competent-authorities.service";
import {UserRepository} from "../../user/states/user.repository";
import {ToastService} from "../../../shared/services/toast.service";
import {TranslocoService} from "@ngneat/transloco";
import {AnimalHintModel} from "../models/animal-hint.model";
import {AnimalOriginReasonForTransfer} from "../models/animal-origin-reason-for-transfer.enum";
import {AnimalCharacteristicsModel} from "../models/animal-characteristics.model";
import { AnimalSheltersRepository } from '../states/animal-shelter.repository';
import {AnimalRepository} from "../states/animal.repository";
import {HelperService} from "../../../shared/services/helper.service";
import { AnimalCategory } from '../models/animal-category.enum';
import { AnimalType } from '../models/animal-type.enum';
import { ContactModel } from '../../contact/models/contact.model';

@Component({
  selector: 'app-animal-add-dialog',
  templateUrl: './animal-add-dialog.component.html',
  styleUrls: ['./animal-add-dialog.component.scss']
})
export class AnimalAddDialogComponent implements OnInit {

  visible: boolean = false;
  items: MenuItem[] = [];
  activeTabIndex: number = 0;
  activeTabIndexLength: number = 0;
  selectedCategory!: AnimalFilter;

  formGroup: FormGroup;
  formGroupStatus$: any;

  categoryFilters: AnimalFilter[] = [];
  animalTypeOptions: AnimalTypeModel[] = [];
  allAnimalTypes: AnimalTypeModel[] = [];
  animalCategoryOptions: AnimalCategoryModel[] = [];
  coatTypeOptions: AnimalCoatTypeModel[] = [];
  genderOptions: AnimalGenderModel[] = [];
  formSubmitted: boolean = false;
  originCompetentAuthorityOptions: CompetentAuthorityModel[] = [];
  originPersonSalutationOptions: PersonTitleModel[] = [];
  animalShelterId: number | undefined;
  hintsOptions: AnimalHintModel[] | undefined;
  characteristicsOptions: AnimalCharacteristicsModel[] | undefined;
  searchTerm: string = '';
  animalSearchResult: AnimalModel[] = [];
  resumeAnimal: AnimalModel = {} as AnimalModel;
  resumeAnimalSteps: boolean = false;
  reasonGivenSet: string = '';
  reasonsForTransfer = AnimalOriginReasonForTransfer;

  validationStatusChangedOwner: boolean = true;
  validationStatusChangedGiver: boolean = true;
  contactGiver: ContactModel = {} as ContactModel;
  contactOwner: ContactModel = {} as ContactModel;
  existingGiverContact: boolean = false;
  existingOwnerContact: boolean = false;
  existingContactOwnerUpdated: boolean = false;
  existingContactGiverUpdated: boolean = false;

  constructor(
    public animalService: AnimalService,
    private _animalAddService: AnimalAddService,
    private _competentAuthoritiesService: CompetentAuthoritiesService,
    private _userRepository: UserRepository,
    private _toast: ToastService,
    private _translateService: TranslocoService,
    private _animalSheltersRepository: AnimalSheltersRepository,
    private cdr: ChangeDetectorRef,
    private _animalRepository: AnimalRepository,
    private _helperService: HelperService
  ) {
    this.formGroup = new FormGroup(
      {
        oldBookNumber: new FormControl<string | null>(null),
        animalShelter: new FormControl<string | null>(null),
        type: new FormControl<AnimalTypeModel | null>(null, Validators.required),
        category: new FormControl<AnimalCategoryModel | null>(null, Validators.required),
        sex: new FormControl<string | null>(null, Validators.required),
        name: new FormControl<string | null>(null),
        nameOld: new FormControl<string | null>(null),
        nameNew: new FormControl<string | null>(null),
        birthApprox: new FormControl<boolean>(false),
        birthdayType: new FormControl<boolean | null>(false),
        birthYear: new FormControl<Date | null>(null),
        birthday: new FormControl<Date | null>(null),
        breed: new FormControl<string | null>(null, Validators.required),
        transponderNumber: new FormControl<string | null>(null, Validators.required),
        lifeNumber: new FormControl<string | null>(null),
        ringNumber: new FormControl<string | null>(null),
        petPassportNumber: new FormControl<string | null>(null),
        equinePassportNumber: new FormControl<string | null>(null),
        earTag: new FormControl<string | null>(null),
        tattoo: new FormControl<string | null>(null),
        tattooLocation: new FormControl<string | null>(null),
        //Origin
        originCompetentAuthority: new FormControl<string | null>(null, Validators.required),
        originDateOfReceipt: new FormControl<Date | null>(null, Validators.required),
        originSpecialInformation: new FormControl<string | null>(null),
        originConditionOfCare: new FormControl<string | null>(null),
        originNutritionalStatus: new FormControl<string | null>(null),
        originOtherComments: new FormControl<string | null>(null),
        originLocation: new FormControl<string | null>(null),
        reasonForGiving: new FormControl<string | null>(null),
      },
      {
        updateOn: 'change',
      }
    );

    this.formGroup.get('castratedDate')?.valueChanges.subscribe(() => {
      this.onCastratedDatesChange();
    });

    this.formGroup.get('chemicalCastratedDate')?.valueChanges.subscribe(() => {
      this.onCastratedDatesChange();
    });
  }

  ngOnInit(): void {
    const activeAnimalShelter = this._animalSheltersRepository.getActiveAnimalShelter();
    if (activeAnimalShelter) {
      this._competentAuthoritiesService.getCompetentAuthoritiesByShelterId(activeAnimalShelter.id).pipe(take(1)).subscribe((competentAuthorities) => {
        this.originCompetentAuthorityOptions = competentAuthorities;
        this.cdr.detectChanges();
      });
    }
    this.items = [
      { label: 'Suche' },
      { label: 'Kategorie' },
      { label: 'Allgemein' },
      { label: 'Abgabegrund' },
      { label: 'Herkunft' },
    ];
    this.activeTabIndexLength = this.items.length - 1;

    this.originPersonSalutationOptions = this._helperService.getSalutationOptions();

    this.formGroupStatus$ = this.formGroup.statusChanges.pipe(
      map((status) => status)
    );

    this.categoryFilters = this.animalService.getFilterCategories();
    this.allAnimalTypes = this.animalService.getAnimalTypes();
    this.animalCategoryOptions = this.animalService.getAnimalCategories();
    this.genderOptions = this.animalService.getGenderOptions();
    this.coatTypeOptions = this.animalService.getCoatTypes();
    this.hintsOptions = this.animalService.getHints();
    this.characteristicsOptions = this.animalService.getCharacteristics();
    this.visible = true;

    this._userRepository.activeUser$.pipe(take(1), filter((v) => v === v)).subscribe(user => {
      this.animalShelterId = user?.defaultAnimalShelter?.id;
    });
  }

  closeModal() {
    this._animalAddService.toggleAddDialog(false);
  }

  resetAllFormFields(formGroup: FormGroup): void {
    formGroup.reset({
      oldBookNumber: null,
      animalShelter: null,
      type: null,
      category: null,
      sex: null,
      name: null,
      nameOld: null,
      birthApprox: false,
      birthdayType: false,
      birthYear: null,
      birthday: null,
      breed: null,
      transponderNumber: null,
      lifeNumber: null,
      ringNumber: null,
      petPassportNumber: null,
      equinePassportNumber: null,
      earTag: null,
      tattoo: null,
      tattooLocation: null,
      motherId: null,
      originCompetentAuthority: null,
      originDateOfReceipt: null,
      originSpecialInformation: null,
      originConditionOfCare: null,
      originNutritionalStatus: null,
      originOtherComments: null,
      originLocation: null
    });
  }

  
  resetOriginFields(formGroup: FormGroup): void {
    formGroup.reset({
      originCompetentAuthority: null,
      originDateOfReceipt: null,
      originSpecialInformation: null,
      originConditionOfCare: null,
      originNutritionalStatus: null,
      originOtherComments: null,
      originLocation: null
    });
    this.contactGiver = {} as ContactModel;
    this.contactOwner = {} as ContactModel;
    this.validationStatusChangedGiver = true;
    this.validationStatusChangedOwner = true;
  }

  lastStep() {
    if (this.resumeAnimalSteps && this.activeTabIndex === 0) {
      this.items = [
        { label: 'Suche' },
        { label: 'Kategorie' },
        { label: 'Allgemein' },
        { label: 'Abgabegrund' },
        { label: 'Herkunft' }
      ]
      this.activeTabIndexLength = this.items.length - 1;
      this.resumeAnimal = {} as AnimalModel;
      this.resumeAnimalSteps = false;
      this.activeTabIndex = 0;
    } else if (this.activeTabIndex > 0) {
      this.activeTabIndex--;
    }

  }

  nextStep() {
    if (this.resumeAnimal) {

    } else if (this.activeTabIndex < this.activeTabIndexLength) {
    }
    this.activeTabIndex++;
  }

  isNextStepDisabled(): boolean {
    let isDisabled = false;
  
    if (
      (!this.resumeAnimalSteps && this.activeTabIndex === 0) ||
      (!this.resumeAnimalSteps && this.activeTabIndex === 1 && this.selectedCategory === undefined) ||
      (this.getTabContent() === 'reasonForDelivery' && (!this.reasonGivenSet || !this.reasonGivenSet.length))
    ) {
      isDisabled = true;
    }

    if (this.getTabContent() === 'general') {
      return !(
        this.formGroup.get('type')?.valid &&
        this.formGroup.get('category')?.valid &&
        this.formGroup.get('sex')?.valid &&
        this.formGroup.get('breed')?.valid &&
        this.formGroup.get('transponderNumber')?.valid
      );
    }
  
    return isDisabled;
  }

  addNewAnimalStep() {
    this.activeTabIndex = 1;
  }

  resumeAnimalStep(animal: AnimalModel) {
    this.resumeAnimal = animal;
    this.resumeAnimalSteps = true;
    this.items = [
      { label: 'Allgemein' },
      { label: 'Abgabegrund' },
      { label: 'Herkunft' }
    ];
    this.activeTabIndexLength = this.items.length - 1;
    this.activeTabIndex = -1;
    this.onCategoryChange(animal.category);
    this.formGroup.patchValue(this.resumeAnimal);
    this.formGroup.get('name')?.setValue(null);
    this.formGroup.get('nameOld')?.setValue(this.resumeAnimal.name);
    this.formGroup.get('oldBookNumber')?.setValue(this.resumeAnimal.bookNumber);
  }

  onSearch() {
    this._animalRepository.searchAnimals(this.searchTerm).subscribe(
      (response: AnimalModel[]) => {
        this.animalSearchResult = response;
      },
      error => {
        console.error('Fehler bei der Suche:', error);
      }
    );
  }

  addMotherAnimal(animal: AnimalModel) {
    if (!this.formGroup.contains('motherId')) {
      this.formGroup.addControl('motherId', new FormControl<number | null>(null, Validators.required));
    }
    this.formGroup.get('motherId')?.setValue(animal.id);
  }

  getTabContent() {
    switch (this.items[this.activeTabIndex]?.label) {
      case 'Suche':
          return 'search';
      case 'Kategorie':
          return 'category';
      case 'Allgemein':
          return 'general';
      case 'Abgabegrund':
          return 'reasonForDelivery';
      case 'Herkunft':
          return 'origin';
      default:
          return '';
    }

  }

  selectCategory(filter: AnimalFilter) {
    this.resetAllFormFields(this.formGroup);
    const category = this.mapFilterIdToCategory(filter.id);
    this.selectedCategory = filter;
    if (category) {
      this.formGroup.get('category')?.setValue(category);

      this.animalTypeOptions = this.filterAnimalTypesByCategory(category);

      if (this.animalTypeOptions.length > 0) {
        this.formGroup.get('type')?.setValue(this.animalTypeOptions[0].value);
      } else {
        this.formGroup.get('type')?.setValue('');
      }
      this.nextStep();
    }
  }

  handleBarcodeScanned(event: { field?: string; value: string }) {
    if (event.field) {
      this.formGroup.get(event.field)?.setValue(event.value);
    }
  }

  mapFilterIdToCategory(id: string): AnimalCategory | undefined {
    const categoryMap: { [key: string]: AnimalCategory } = {
      dog: AnimalCategory.DOG,
      cat: AnimalCategory.CAT,
      horse: AnimalCategory.HORSE,
      small_animal: AnimalCategory.SMALL_ANIMAL,
      reptile: AnimalCategory.REPTILE,
      wild_animal: AnimalCategory.WILD_ANIMAL,
      farm_animal: AnimalCategory.FARM_ANIMAL,
      bird: AnimalCategory.BIRD,
    };

    return categoryMap[id.toLowerCase()];
  }

  filterAnimalTypesByCategory(category: AnimalCategory): AnimalTypeModel[] {
    const animalCategoryTypeMap = {
      [AnimalCategory.DOG]: [AnimalType.DOG],
      [AnimalCategory.CAT]: [AnimalType.CAT],
      [AnimalCategory.HORSE]: [AnimalType.HORSE, AnimalType.DONKEY, AnimalType.ALPACA],
      [AnimalCategory.SMALL_ANIMAL]: [AnimalType.FERRET, AnimalType.RODENT, AnimalType.HARE, AnimalType.MARTEN, AnimalType.TENREC],
      [AnimalCategory.REPTILE]: [AnimalType.TURTLE, AnimalType.LIZARD, AnimalType.SNAKE],
      [AnimalCategory.WILD_ANIMAL]: [AnimalType.BEAR, AnimalType.ELK],
      farm_animal: AnimalCategory.FARM_ANIMAL,
      [AnimalCategory.BIRD]: [AnimalType.BIRD],
    };

    const allowedTypes = animalCategoryTypeMap[category] || [];

    return this.allAnimalTypes.filter(typeModel =>
      allowedTypes.includes(typeModel.value as AnimalType)
    );
  }

  shouldDisableTypeDropdown(): boolean {
    return this.animalTypeOptions.length === 1;
  }

  onCategoryChange(category: any) {
    const filter = this.categoryFilters.find(filter => filter.id.toLowerCase() === category);
    if (filter) {
      this.selectCategory(filter);
    }
  }

  isSelectedCategory(filter: AnimalFilter) {
    return this.selectedCategory === filter;
  }

  reasonGiven(reason: string) {
    if (this.reasonGivenSet && this.reasonGivenSet !== reason) {
      this.resetOriginFields(this.formGroup);
      if (reason !== this.reasonsForTransfer.BORN_IN_SHELTER && this.formGroup.contains('motherId')) {
        this.formGroup.addControl('motherId', new FormControl<number | null>(null, Validators.required));
      }
    }
    this.reasonGivenSet = reason;
    this.formGroup.get('reasonForGiving')?.setValue(this.reasonGivenSet);

    this.nextStep();
  }

  onValidationStatusChangedOwner($event: boolean) {
    this.validationStatusChangedOwner = $event;
  }
  onContactUpdateOwner($event: any) {
    this.contactOwner = $event;
  }
  onValidationStatusChangedGiver($event: boolean) {
    this.validationStatusChangedGiver = $event;
  }

  onContactUpdateGiver($event: any) {
    this.contactGiver = $event ? $event : null;
  }

  onExistingContactUpdatedOwner($event: boolean) {
    this.existingContactOwnerUpdated = $event;
  }
  onExistingContactSelectedOwner($event: ContactModel) {
    this.contactOwner = $event;
    this.existingOwnerContact = Object.keys($event).length ? true : false;
  }
  onExistingContactUpdatedGiver($event: boolean) {
    this.existingContactGiverUpdated = $event;
  }

  onExistingContactSelectedGiver($event: ContactModel) {
    this.contactGiver = $event ? $event : {} as ContactModel;
    this.existingGiverContact = Object.keys($event).length ? true : false;
  }

  getInvalidFormControls() {
    const invalidControls = Object.keys(this.formGroup.controls).filter(
      key => this.formGroup.controls[key].invalid
    );
  
    return invalidControls.length === 0 && this.validationStatusChangedOwner && this.validationStatusChangedGiver;
  }

  saveChanges() {
    this.formSubmitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    if (!this.animalShelterId) {
      console.error('No animalShelterId found');
      // TODO: Whats going on if NO animalShelterId is found?
      return;
    }

    if (this.formGroup.get('size')?.value && this.formGroup.get('size')?.value !== 0) {
      this.formGroup.patchValue({size: this.formGroup.get('size')?.value * 10});
    } else {
      this.formGroup.patchValue({size: null});
    }

    const birthYear = this.formGroup.get('birthYear')?.value as string;

    this.formGroup.get('birthdayType')?.value ?
      this.formGroup.patchValue({
        birthday: null,
        birthYear: new Date(new Date(birthYear).getFullYear(), 0, 1)
      }) :
      this.formGroup.patchValue({birthYear: null});

    if (this.formGroup.get('motherId')?.value) {
      this.formGroup.addControl('mother', new FormControl<{} | null>(null));
      this.formGroup.patchValue({mother: {id: this.formGroup.get('motherId')?.value}});
      this.formGroup.removeControl('motherId');
    }
    let dateOfReceipt = null;
    if (this.formGroup.get('originDateOfReceipt')?.value) {
      dateOfReceipt = this._helperService.determineDateOfReceipt(
        this.formGroup.get('originDateOfReceipt')?.value,
        this.formGroup.get('originDateOfReceipt')?.value
      );
      this.formGroup.patchValue({ originDateOfReceipt: dateOfReceipt });
    }

    this.formGroup.patchValue({animalShelter: {id: this.animalShelterId}});
    if (this.resumeAnimalSteps) {
      this.formGroup.addControl('bookNumber', new FormControl<string>(''));
      const updateAnimalPayload = {
        id: this.resumeAnimal.id,
        animalShelter: {id: this.animalShelterId},
        category: this.formGroup.get('category')?.value?.id ?? this.resumeAnimal.category,
        type: this.formGroup.get('type')?.value?.id ?? this.resumeAnimal.type,
        breed: this.formGroup.get('breed')?.value ?? this.resumeAnimal.breed,
        bookNumber: '',
        oldBookNumber: this.formGroup.get('oldBookNumber')?.value,
        petPassportNumber: this.formGroup.get('petPassportNumber')?.value ?? this.resumeAnimal.petPassportNumber,
        equinePassportNumber: this.formGroup.get('equinePassportNumber')?.value ?? this.resumeAnimal.equinePassportNumber,
        lifeNumber: this.formGroup.get('lifeNumber')?.value ?? this.resumeAnimal.lifeNumber,
        transponderNumber: this.formGroup.get('transponderNumber')?.value ?? this.resumeAnimal.transponderNumber,
        sex: this.formGroup.get('sex')?.value ?? this.resumeAnimal.sex,
        name: this.formGroup.get('name')?.value ?? this.resumeAnimal.name,
        nameOld: this.formGroup.get('nameOld')?.value ?? this.resumeAnimal.nameOld,
        nameNew: this.formGroup.get('nameNew')?.value ?? this.resumeAnimal.nameNew,
        weight: this.formGroup.get('weight')?.value ?? this.resumeAnimal.weight,
        size: this.formGroup.get('size')?.value ?? this.resumeAnimal.size,
        birthday: this.formGroup.get('birthday')?.value ?? this.resumeAnimal.birthday,
        birthYear: this.formGroup.get('birthYear')?.value ?? this.resumeAnimal.birthYear,
        birthPlace: this.formGroup.get('birthPlace')?.value ?? this.resumeAnimal.birthPlace,
        specialInformation: this.formGroup.get('specialInformation')?.value ?? this.resumeAnimal.specialInformation,
        internalDetails: this.formGroup.get('internalDetails')?.value ?? this.resumeAnimal.internalDetails,
        externalDetails: this.formGroup.get('externalDetails')?.value ?? this.resumeAnimal.externalDetails,
        tattoo: this.formGroup.get('tattoo')?.value ?? this.resumeAnimal.tattoo,
        tattooLocation: this.formGroup.get('tattooLocation')?.value ?? this.resumeAnimal.tattooLocation,
        ringNumber: this.formGroup.get('ringNumber')?.value ?? this.resumeAnimal.ringNumber,
        earTag: this.formGroup.get('earTag')?.value ?? this.resumeAnimal.earTag,
        externalShortDescription: this.formGroup.get('externalShortDescription')?.value ?? this.resumeAnimal.externalShortDescription,
        externalDescription: this.formGroup.get('externalDescription')?.value ?? this.resumeAnimal.externalDescription,
        castrated: this.formGroup.get('castrated')?.value ?? this.resumeAnimal.castrated,
        castratedDate: this.formGroup.get('castratedDate')?.value ?? this.resumeAnimal.castratedDate,
        castratedNote: this.formGroup.get('castratedNote')?.value ?? this.resumeAnimal.castratedNote,
        chemicalCastratedDate: this.formGroup.get('chemicalCastratedDate')?.value ?? this.resumeAnimal.chemicalCastratedDate,
        sterilized: this.formGroup.get('sterilized')?.value ?? this.resumeAnimal.sterilized,
        sterilizedDate: this.formGroup.get('sterilizedDate')?.value ?? this.resumeAnimal.sterilizedDate,
        websiteUrl: this.formGroup.get('websiteUrl')?.value ?? this.resumeAnimal.websiteUrl,
        hints: this.formGroup.get('hints')?.value ?? this.resumeAnimal.hints,
        syncWithWebsite: this.formGroup.get('syncWithWebsite')?.value ?? this.resumeAnimal.syncWithWebsite,
        websiteMetaTitle: this.formGroup.get('websiteMetaTitle')?.value ?? this.resumeAnimal.websiteMetaTitle,
        websiteMetaDescription: this.formGroup.get('websiteMetaDescription')?.value ?? this.resumeAnimal.websiteMetaDescription,
        sanctuary: this.formGroup.get('sanctuary')?.value ?? this.resumeAnimal.sanctuary,
        fosterHomeWanted: this.formGroup.get('fosterHomeWanted')?.value ?? this.resumeAnimal.fosterHomeWanted,
        mother: this.formGroup.get('motherId')?.value ?? this.resumeAnimal.motherId
      };
      
      this._animalRepository.update(this.resumeAnimal.id, updateAnimalPayload)
        .then(() => {
          this._animalAddService.saveOrigin(
            this.formGroup.value,
            this.resumeAnimal.id,
            this.contactGiver,
            this.contactOwner,
            this.existingGiverContact,
            this.existingContactGiverUpdated,
            this.existingOwnerContact,
            this.existingContactOwnerUpdated
          );
          this._toast.showSuccess(this._translateService.translate('animalAdd.toast.saveSuccess'));
          this.closeModal();
        })
        .catch(error => {
          console.error('Error saving animal:', error);
        });
    } else {
      this._animalRepository.create(this.formGroup.value)
        .then(newAnimalId => {
          if (newAnimalId !== undefined && newAnimalId !== null) {
            this._animalAddService.saveOrigin(
              this.formGroup.value,
              newAnimalId,
              this.contactGiver,
              this.contactOwner,
              this.existingGiverContact,
              this.existingContactGiverUpdated,
              this.existingOwnerContact,
              this.existingContactOwnerUpdated
            );
            this._toast.showSuccess(this._translateService.translate('animalAdd.toast.saveSuccess'));
            this.closeModal();
          }
        })
        .catch(error => {
          console.error('Error saving animal:', error);
        });
    }
  }

  onCastratedDatesChange() {
    const castratedDate = this.formGroup.get('castratedDate')?.value;
    const chemicalCastratedDate = this.formGroup.get('chemicalCastratedDate')?.value;

    if (castratedDate || chemicalCastratedDate) {
      this.formGroup.get('castrated')?.setValue(true);
    } else {
      this.formGroup.get('castrated')?.setValue(false);
    }
  }
}
