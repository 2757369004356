<div class="w-full">
  <div class="flex flex-row w-full justify-content-center mb-2">
    <button class="mr-2" pButton type="button" [label]="'contacts.addNewContact' | transloco" (click)="createNewContact()"></button>
    <button pButton type="button" [label]="'contacts.searchContact' | transloco" (click)="openSearchModal()"></button>
  </div>
  <p-dialog [(visible)]="showSearchModal" [header]="'contacts.searchModalHeader' | transloco" [draggable]="false"
    [resizable]="false" [modal]="true" [style]="{width: '100vw', height: '100vh'}" [contentStyle]="{ 'overflow': 'hidden' }"
    (onHide)="closeSearchModal()"
  >
    <ng-template pTemplate="closeicon">
      <span class="closeicon pi pi-times"></span>
    </ng-template>
    <ng-template pTemplate="header">
      <h3>{{ 'contacts.searchModalHeader' | transloco }}</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <div class="flex flex-row w-full justify-content-center mt-4 mb-2">
        <input type="text" class="w-full" pInputText [(ngModel)]="searchTerm" [placeholder]="'contacts.searchPlaceholder' | transloco" (keydown.enter)="onSearch()">
        <button class="ml-1" pButton type="button" [label]="'contacts.searchButton' | transloco" (click)="onSearch()" [disabled]="searchTerm.length < 3"></button>
      </div>
      <div class="flex-grow-1 overflow-auto" *ngIf="contacts.length">
        <p-table [value]="contacts" class="p-mt-4">
          <ng-template pTemplate="header">
            <tr>
              <th>{{ 'contacts.company' | transloco }}</th>
              <th>{{ 'contacts.firstName' | transloco }}</th>
              <th>{{ 'contacts.lastName' | transloco }}</th>
              <th>{{ 'contacts.address' | transloco }}</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-contact>
            <tr>
              <td>{{ contact.companyName }}</td>
              <td>{{ contact.firstName }}</td>
              <td>{{ contact.lastName }}</td>
              <td>{{ contact.street }}, {{ contact.city }}, {{ contact.zipCode }}</td>
              <td class="flex flex-row-reverse">
                <button pButton type="button" [label]="'contacts.selectContact' | transloco" (click)="takeContact(contact)"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-template>
    <p-footer>
      <div class="flex justify-content-end">
        <button pButton type="button" [label]="'contacts.cancel' | transloco" (click)="closeSearchModal()"></button>
      </div>
    </p-footer>
  </p-dialog>

  <div class="p-error" *ngIf="validationError">{{ validationError }}</div>

  <div *ngIf="showForm || objectKeys(newContact).length" class="col-12">
    <div class="grid">
      <div class="col-3">
        <label class="py-1" for="contactGender">{{ 'contacts.gender' | transloco }}</label>
        <div class="p-inputgroup">
          <p-dropdown
            class="p-inputdropdown-sm w-full"
            id="contactGender"
            [options]="personSalutationOptions"
            optionLabel="name"
            optionValue="value"
            [(ngModel)]="newContact.gender"
            (ngModelChange)="onInputChange()"
            [disabled]="isExistingContact && !editableFields['gender']"
          ></p-dropdown>
          <button *ngIf="isExistingContact" pButton type="button" icon="pi pi-pencil" (click)="enableField('gender')"></button>
        </div>
      </div>
      <div class="col-4">
        <label class="py-1" for="contactLastName">{{ 'contacts.lastName' | transloco }}</label>
        <div class="p-inputgroup">
          <input
            type="text"
            pInputText
            class="p-inputtext-sm w-full"
            id="contactLastName"
            [(ngModel)]="newContact.lastName"
            (ngModelChange)="onInputChange()"
            [disabled]="isExistingContact && !editableFields['lastName']"
          />
          <button *ngIf="isExistingContact" pButton type="button" icon="pi pi-pencil" (click)="enableField('lastName')"></button>
       </div>
      </div>
      <div class="col-5">
        <label class="py-1" for="contactFirstName">{{ 'contacts.firstName' | transloco }}</label>
        <div class="p-inputgroup">
          <input
            type="text"
            pInputText
            class="p-inputtext-sm w-full"
            id="contactFirstName"
            [(ngModel)]="newContact.firstName"
            (ngModelChange)="onInputChange()"
            [disabled]="isExistingContact && !editableFields['firstName']"
          />
          <button *ngIf="isExistingContact" pButton type="button" icon="pi pi-pencil" (click)="enableField('firstName')"></button>
       </div>
      </div>
      <div class="col-12">
        <label class="py-1" for="contactCompanyName">{{ 'contacts.company' | transloco }}</label>
        <div class="p-inputgroup">
          <input
            type="text"
            pInputText
            class="p-inputtext-sm w-full"
            id="contactCompanyName"
            [(ngModel)]="newContact.companyName"
            (ngModelChange)="onInputChange()"
            [disabled]="isExistingContact && !editableFields['companyName']"
          />
          <button *ngIf="isExistingContact" pButton type="button" icon="pi pi-pencil" (click)="enableField('companyName')"></button>
       </div>
      </div>
      <div class="col-5">
        <label class="py-1" for="contactStreet">{{ 'contacts.street' | transloco }}</label>
        <div class="p-inputgroup">
          <input
            type="text"
            pInputText
            class="p-inputtext-sm w-full"
            id="contactStreet"
            [(ngModel)]="newContact.street"
            (ngModelChange)="onInputChange()"
            [disabled]="isExistingContact && !editableFields['street']"
          />
          <button *ngIf="isExistingContact" pButton type="button" icon="pi pi-pencil" (click)="enableField('street')"></button>
       </div>
      </div>
      <div class="col-2">
        <label class="py-1" for="contactZipCode">{{ 'contacts.zipCode' | transloco }}</label>
        <div class="p-inputgroup">
          <input
            type="text"
            pInputText
            class="p-inputtext-sm w-full"
            id="contactZipCode"
            [(ngModel)]="newContact.zipCode"
            (ngModelChange)="onInputChange()"
            [disabled]="isExistingContact && !editableFields['zipCode']"
          />
          <button *ngIf="isExistingContact" pButton type="button" icon="pi pi-pencil" (click)="enableField('zipCode')"></button>
       </div>
      </div>
      <div class="col-5">
        <label class="py-1" for="contactCity">{{ 'contacts.city' | transloco }}</label>
        <div class="p-inputgroup">
          <input
            type="text"
            pInputText
            class="p-inputtext-sm w-full"
            id="contactCity"
            [(ngModel)]="newContact.city"
            (ngModelChange)="onInputChange()"
            [disabled]="isExistingContact && !editableFields['city']"
          />
          <button *ngIf="isExistingContact" pButton type="button" icon="pi pi-pencil" (click)="enableField('city')"></button>
       </div>
      </div>
      <div class="col-6">
        <label class="py-1" for="contactMobilePhoneNumber">{{ 'contacts.mobilePhone' | transloco }}</label>
        <div class="p-inputgroup">
          <input
            type="text"
            pInputText
            class="p-inputtext-sm w-full"
            id="contactMobilePhoneNumber"
            [(ngModel)]="newContact.mobilePhoneNumber"
            (ngModelChange)="onInputChange()"
            [disabled]="isExistingContact && !editableFields['mobilePhoneNumber']"
          />
          <button *ngIf="isExistingContact" pButton type="button" icon="pi pi-pencil" (click)="enableField('mobilePhoneNumber')"></button>
       </div>
      </div>
      <div class="col-6">
        <label class="py-1" for="contactPhoneNumber">{{ 'contacts.phoneNumber' | transloco }}</label>
        <div class="p-inputgroup">
          <input
            type="text"
            pInputText
            class="p-inputtext-sm w-full"
            id="contactPhoneNumber"
            [(ngModel)]="newContact.phoneNumber"
            (ngModelChange)="onInputChange()"
            [disabled]="isExistingContact && !editableFields['phoneNumber']"
          />
          <button *ngIf="isExistingContact" pButton type="button" icon="pi pi-pencil" (click)="enableField('phoneNumber')"></button>
       </div>
      </div>
      <div class="col-6">
        <label class="py-1" for="contactEmail">{{ 'contacts.email' | transloco }}</label>
        <div class="p-inputgroup">
          <input
            type="text"
            pInputText
            class="p-inputtext-sm w-full"
            id="contactEmail"
            [(ngModel)]="newContact.email"
            (ngModelChange)="onInputChange()"
            [disabled]="isExistingContact && !editableFields['email']"
          />
          <button *ngIf="isExistingContact" pButton type="button" icon="pi pi-pencil" (click)="enableField('email')"></button>
       </div>
      </div>
      <div class="col-6">
        <label class="py-1" for="contactIdCardNumber">{{ 'contacts.idCardNumber' | transloco }}</label>
        <div class="p-inputgroup">
          <input
            type="text"
            pInputText
            class="p-inputtext-sm w-full"
            id="contactIdCardNumber"
            [(ngModel)]="newContact.idCardNumber"
            (ngModelChange)="onInputChange()"
            [disabled]="isExistingContact && !editableFields['idCardNumber']"
          />
          <button *ngIf="isExistingContact" pButton type="button" icon="pi pi-pencil" (click)="enableField('idCardNumber')"></button>
       </div>
      </div>
    </div>
  </div>
</div>
