import { Component, ViewChild, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { BrowserMultiFormatReader } from '@zxing/browser';

@Component({
  selector: 'app-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss'],
})
export class BarcodeScannerComponent {
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @Output() barcodeScanned = new EventEmitter<{ field?: string; value: string }>();
  @Input() bindTo?: string;
  isScannerOpen: boolean = false;
  private codeReader: BrowserMultiFormatReader = new BrowserMultiFormatReader();

  constructor() {}

  openScanner() {
    this.isScannerOpen = true;
    setTimeout(() => {
      if (!this.videoElement) {
        console.error('Video element is not initialized.');
        return;
      }

      const videoElement = this.videoElement.nativeElement;

      this.codeReader.decodeFromVideoDevice(undefined, videoElement, (result, error) => {
        if (result) {
          if (this.bindTo) {
            this.barcodeScanned.emit({ field: this.bindTo, value: result.getText() });
          } else {
            this.barcodeScanned.emit({ value: result.getText() });
          }
          this.closeScanner();
        }
      });
    });
  }

  closeScanner() {
    this.isScannerOpen = false;
    BrowserMultiFormatReader.releaseAllStreams();
  }
}
