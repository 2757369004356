<div class="grid">
  <div class="col-12 mb-1 flex justify-content-center">
    <p-button *ngIf="!isEditingMode" (click)="addNewWhereabout()" [disabled]="(isAddMode)">
      <i class="pi pi-plus mr-1"></i>
      {{ 'animalWhereabout.addWhereabout' | transloco }}
    </p-button>
  </div>
</div>
<ng-container *ngFor="let animalWhereaboutsItem of animalWhereaboutsItems | keyvalue: dummyFirst">
  <div class="card mt-2 details-view"
    *ngIf="!isEditingMode"
  >
    <ng-container *ngTemplateOutlet="animalWhereaboutsFormTemplate; context: { key: animalWhereaboutsItem.key }"></ng-container>
    <div class="col-12" *ngIf="animalWhereaboutsItem.key == 'dummy'">
      <div class="flex justify-content-end">
        <p-button [label]="'modal.cancel' | transloco" class="p-button-secondary" (click)="cancelNewWhereabout()"></p-button>
        <p-button [label]="'modal.save' | transloco" class="ml-2 p-button-secondary" (click)="saveNewWhereabout()"></p-button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #animalWhereaboutsFormTemplate let-key="key">
  <ng-container>
    <div class="grid">
      <div class="col-12" *ngIf="!isEditingMode">
        <div class="flex">
          <div class="flex-1 p-4 text-xl font-semibold">
            <span *ngIf="key == 'dummy'">{{ 'animalWhereabout.new' | transloco }} </span>
            {{ 'animalWhereabout.general.headline' | transloco }} <span *ngIf="(key !== 'dummy') && animalWhereaboutsItems[key].type ">|</span>
            <ng-container *ngIf="(key !== 'dummy') && animalWhereaboutsItems[key].type && selectedTypeOption[key]">
              {{ selectedTypeOption[key].name }}
            </ng-container>
          </div>
          <div class="flex-0" *ngIf="key !== 'dummy'">
            <button class="p-button p-button-sm p-button-danger ml-2" (click)="deleteWhereabout(key)">
              <i class="pi pi-trash mr-1"></i>
              {{ 'general.delete' | transloco }}
            </button>
            <p-confirmDialog
              icon="pi pi-exclamation-triangle"
              acceptLabel="{{ 'yes' | transloco }}"
              rejectLabel="{{ 'no' | transloco }}"></p-confirmDialog>
          </div>
        </div>
      </div>
      <div class="col-12">
        <p-accordion>
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="flex justify-content-between w-full  align-items-center">
                <span class="flex-order-0 p-accordion-custom-header-text">{{ 'animalDetails.general.headline' | transloco }}</span>
                <button *ngIf="key !== 'dummy'" class="p-button p-button-sm" (click)="toggleEditDialog(true, 'general', key)">
                  <i class="pi pi-pencil mr-1"></i>
                  {{ 'edit' | transloco }}
                </button>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <ng-container>
                <ng-container *ngTemplateOutlet="generalForm; context: { whereAbout: animalWhereaboutsItems[key]}"></ng-container>
              </ng-container>
            </ng-template>
          </p-accordionTab>

          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="flex justify-content-between w-full  align-items-center">
                <span class="flex-order-0 p-accordion-custom-header-text">{{ 'animalDetails.general.contact' | transloco }}</span>
                <button *ngIf="key !== 'dummy'" class="p-button p-button-sm" (click)="toggleEditDialog(true, 'givingContact', key, ((animalWhereaboutsItems[key].contact && animalWhereaboutsItems[key].contact.id) ? animalWhereaboutsItems[key].contact.id : null))">
                  <i class="pi pi-pencil mr-1"></i>
                  {{ 'edit' | transloco }}
                </button>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <ng-container *ngIf="animalWhereaboutsItems[key].contact && contacts && contacts[animalWhereaboutsItems[key].contact.id]">
                <ng-container *ngTemplateOutlet="contactForm; context: { contact: contacts[animalWhereaboutsItems[key].contact.id]}"></ng-container>
              </ng-container>
              <ng-container *ngIf="key === 'dummy'">
                <ng-container *ngTemplateOutlet="contactForm; context: { contact: contact}"></ng-container>
              </ng-container>
            </ng-template>
          </p-accordionTab>
        </p-accordion>
        </div>
    </div>

  </ng-container>
</ng-template>

<ng-template #generalForm let-whereAbout="whereAbout">
  <div class="grid">
    <div class="col-4">
      <label class="py-1" for="type">{{ 'animalWhereabout.type' | transloco }}</label>
      <p-dropdown
        class="p-inputdropdown-sm w-full"
        id="type"
        [options]="whereAboutTypeOptions"
        optionLabel="name"
        [autoDisplayFirst]="false"
        [(ngModel)]="selectedTypeOption[whereAbout.id]"
        appendTo="body"
        [disabled]="!isEditingMode && !isAddMode"
      >
      </p-dropdown>
    </div>
    <div class="col-4">
      <label class="py-1" for="date">{{ 'animalWhereabout.date' | transloco }}</label>
      <p-calendar
        class="w-full p-inputcalendar-sm"
        id="date"
        [(ngModel)]="whereAbout.date"
        dateFormat="dd.mm.yy"
        [showIcon]="true"
        appendTo="body"
        [disabled]="!isEditingMode && !isAddMode"
      ></p-calendar>
    </div>
    <div class="col-4">
      <label class="py-1" for="date">{{ 'animalWhereabout.dateCare' | transloco }}</label>
      <p-calendar class="w-full p-inputcalendar-sm" id="dateCare"
        [(ngModel)]="whereAbout.dateCare"
        dateFormat="dd.mm.yy"
        [showIcon]="true"
        appendTo="body"
        [disabled]="!isEditingMode && !isAddMode"
      ></p-calendar>
    </div>
    <div class="col-12" *ngIf="isEditingMode || isAddMode || whereAbout.information">
      <label class="py-1" for="information">{{ 'animalWhereabout.information' | transloco }}</label>
      <textarea
        pInputText
        area
        [class.no-border]="!isEditingMode && !isAddMode"
        class="p-inputtext-sm w-full"
        [(ngModel)]="whereAbout.information"
        id="information"
        rows="5"
        [disabled]="!isEditingMode && !isAddMode"
      ></textarea>
    </div>
    <div class="col-12" *ngIf="isAddMode || whereAbout.stillOnWebsite">
      <label for="stillOnWebsite" class="py-1">{{ 'animalWhereabout.labelStillOnWebsite' | transloco}}</label>
      <p-toggleButton
          [(ngModel)]="whereAbout.stillOnWebsite"
          id="stillOnWebsite"
          onLabel="{{ 'yes' | transloco }}" 
          offLabel="{{ 'no' | transloco }}" />
    </div>
  </div>
</ng-template>

<ng-template #contactForm let-contact="contact">
  <ng-container *ngIf="!contact && !isAddMode">
    <span>{{ 'contacts.noContactExist' | transloco }}</span>
  </ng-container>
  <ng-container *ngIf="contact && !isAddMode">
    <div class="grid">
      <div class="col-3" *ngIf="contact.gender">
        <label class="py-1" for="personSalutation">
          {{ 'animalAdd.origin.person.salutation' | transloco }}
        </label>
        <p-dropdown
          class="p-inputdropdown-sm w-full"
          [options]="personSalutationOptions"
          optionLabel="name"
          optionValue="value"
          [autoDisplayFirst]="false"
          [(ngModel)]="contact.gender"
          id="personSalutation"
          appendTo="body"
          [disabled]="true"
        >
        </p-dropdown>
      </div>
      <div class="col-4" *ngIf="contact.lastName">
        <label class="py-1" for="personLastName">
          {{ 'animalAdd.origin.person.lastName' | transloco }}
        </label>
        <input type="text" class="w-full p-inputtext-sm no-border"
          [disabled]="true"
          [value]="contact.lastName"
          pInputText
        />
      </div>
      <div class="col-5" *ngIf="contact.firstName">
        <label class="py-1" for="personFirstName">
          {{ 'animalAdd.origin.person.firstName' | transloco }}
        </label>
        <input type="text" class="w-full p-inputtext-sm no-border"
          [disabled]="true"
          [value]="contact.firstName"
          id="personFirstName"
          pInputText
        />
      </div>
      <div class="col-12" *ngIf="contact.companyName">
        <label class="py-1" for="companyName">
          {{ 'animalAdd.origin.person.companyName' | transloco }}
        </label>
        <input type="text" class="w-full p-inputtext-sm no-border"
          [disabled]="true"
          [value]="contact.companyName"
          id="companyName"
          pInputText
        />
      </div>
      <div class="col-6" *ngIf="contact.street">
        <label class="py-1" for="personStreet">
          {{ 'animalAdd.origin.person.street' | transloco }}
        </label>
        <input type="text" class="w-full p-inputtext-sm no-border"
          [disabled]="true"
          [value]="contact.street"
          id="personStreet"
          pInputText
        />
      </div>
      <div class="col-2" *ngIf="contact.zipCode">
        <label class="py-1" for="personZipCode">
          {{ 'animalAdd.origin.person.zipCode' | transloco }}
        </label>
        <input
          type="text"
          class="w-full p-inputtext-sm no-border"
          [disabled]="true"
          [value]="contact.zipCode"
          id="personZipCode"
          [class.no-border]="!isEditingMode && !isAddMode"
          pInputText
        />
      </div>
      <div class="col-4" *ngIf="contact.city">
        <label class="py-1" for="personCity">
          {{ 'animalAdd.origin.person.city' | transloco }}
        </label>
        <input
          type="text"
          class="w-full p-inputtext-sm no-border"
          [disabled]="true"
          [value]="contact.city"
          id="personCity"
          [class.no-border]="!isEditingMode && !isAddMode"
          pInputText
        />
      </div>
      <div class="col-6" *ngIf="contact.mobilePhoneNumber">
        <label class="py-1" for="personMobileNumber">
          {{ 'animalAdd.origin.person.mobileNumber' | transloco }}
        </label>
        <input
          type="text"
          class="w-full p-inputtext-sm no-border"
          [disabled]="true"
          [value]="contact.mobilePhoneNumber"
          id="personMobileNumber"
          pInputText
        />
      </div>
      <div class="col-6" *ngIf="contact.phoneNumber">
        <label class="py-1" for="personPhoneNumber">
          {{ 'animalAdd.origin.person.phoneNumber' | transloco }}
        </label>
        <input
          type="text"
          class="w-full p-inputtext-sm no-border"
          [disabled]="true"
          [value]="contact.phoneNumber"
          id="personPhoneNumber"
          [class.no-border]="!isEditingMode && !isAddMode"
          pInputText
        />
      </div>
      <div class="col-6" *ngIf="contact.email">
        <label class="py-1" for="personEmail">
          {{ 'animalAdd.origin.person.email' | transloco }}
        </label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon" [pTooltip]="'animalAdd.origin.person.email' | transloco" tooltipPosition="top">
            <a class="absolute" *ngIf="contact.email" [href]="'mailto:' + contact.email">
              <i class="pi pi-envelope"></i>
            </a>
          </span>
          <input
            type="text"
            class="w-full p-inputtext-sm no-border"
            [disabled]="true"
            [value]="contact.email"
            id="personEmail"
            [class.no-border]="!isEditingMode && !isAddMode"
            pInputText
          />
        </div>
      </div>
      <div class="col-6" *ngIf="contact.idCardNumber">
        <label class="py-1" for="personIdCardNumber">
          {{ 'animalAdd.origin.person.idCardNumber' | transloco }}
        </label>
        <input
          type="text"
          class="w-full p-inputtext-sm no-border"
          [disabled]="true"
          [value]="contact.idCardNumber"
          id="personIdCardNumber"
          pInputText
        />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isAddMode">
    <app-choose-contact 
      [newContact]="editingItem"
      [isExistingContact]="existingContact"
      (newContactUpdated)="onContactUpdate($event)" 
      (validationStatusChanged)="onValidationStatusChanged($event)"
      (existingContactSelected)="onExistingContactSelected($event)"
      (existingContactUpdated)="onExistingContactUpdated($event)"
    >
    </app-choose-contact>
  </ng-container>
</ng-template>

<p-dialog
    [(visible)]="openEdit"
    [style]="{ width: '90vw', height: '90vh' }"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    (onHide)="toggleEditDialog(false, '', '', null)"
>
  <ng-template pTemplate="closeicon">
    <span class="closeicon pi pi-times"></span>
  </ng-template>
  <ng-template pTemplate="header">
    <div class="flex align-items-center mb-3">
      <div class="px-4">
        <app-animal-current-category
            [animal]="animal"
        ></app-animal-current-category>
      </div>
      <div class="flex px-4 animal-name-headline">
        {{ animal?.displayName }}
      </div>
      <div class="flex px-4 animal-key">
        <img src="./assets/images/icon_key_green.svg" alt="key"/>
        <p>{{ animal?.bookNumber }} <span *ngIf="animal?.oldBookNumber">({{animal?.oldBookNumber}})</span></p>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <ng-container *ngIf="editType === 'general'">
      <ng-container *ngTemplateOutlet="generalForm; context: { key: editDialogWhereAboutKey, whereAbout: editingItem}"></ng-container>
    </ng-container>
    <ng-container *ngIf="editType === 'givingContact'">
      <app-choose-contact 
        [newContact]="editingItem"
        [isExistingContact]="existingContact"
        (newContactUpdated)="onContactUpdate($event)" 
        (validationStatusChanged)="onValidationStatusChanged($event)"
        (existingContactSelected)="onExistingContactSelected($event)"
        (existingContactUpdated)="onExistingContactUpdated($event)"
      >
      </app-choose-contact>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex">
      <div class="flex-1">
        <button class="p-button p-button-sm" (click)="toggleEditDialog(false, '', '', null)">
          {{ 'modal.cancel' | transloco }}
        </button>
        <button class="p-button p-button-sm" (click)="save(editingItem)" [disabled]="!validationStatusChanged">
          {{ 'modal.save' | transloco }}
        </button>
      </div>
    </div>
  </ng-template>
</p-dialog>
