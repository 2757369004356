import {Injectable} from "@angular/core";
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnimalEditService {
  editDialogVisible = new BehaviorSubject<{ [tab: string]: boolean | string }>({});
  moveDialogVisible = new BehaviorSubject<boolean>(false);

  toggleEditDialog(openTab: { [tab: string]: boolean | string }) {
    this.editDialogVisible.next(openTab);
  }

  toggleMoveDialog() {
    this.moveDialogVisible.next(!this.moveDialogVisible.value);
  }
}