import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {ToastModule} from "primeng/toast";
import {BreadcrumbModule} from "primeng/breadcrumb";
import {MenubarModule} from "primeng/menubar";
import {TieredMenuModule} from "primeng/tieredmenu";
import {AvatarModule} from "primeng/avatar";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {AccordionModule} from "primeng/accordion";
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from "primeng/inputtext";
import {TranslocoModule} from "@ngneat/transloco";
import {YearDatepipe} from "./pipes/year.datepipe";
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import {FileUploadModule} from "primeng/fileupload";
import { WaitingDialogComponent } from './components/waiting-dialog/waiting-dialog.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollTopModule } from 'primeng/scrolltop';
import { AnimalImageContainerComponent } from './components/animal-image-container/animal-image-container.component';
import { ImageModule } from 'primeng/image';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { BarcodeScannerComponent } from './components/barcode-scanner/barcode-scanner.component';


@NgModule({
  declarations: [
    YearDatepipe,
    ImageUploadComponent,
    WaitingDialogComponent,
    AnimalImageContainerComponent,
    BarcodeScannerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslocoModule,
    ReactiveFormsModule,
    // PRIME NG
    ToastModule,
    BreadcrumbModule,
    MenubarModule,
    TieredMenuModule,
    AvatarModule,
    ButtonModule,
    RippleModule,
    AccordionModule,
    DropdownModule,
    InputTextModule,
    FileUploadModule,
    ProgressSpinnerModule,
    ScrollTopModule,
    ImageModule,
    PaginatorModule,
    TableModule,
    DialogModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    TranslocoModule,
    ReactiveFormsModule,
    // PRIME NG
    ToastModule,
    BreadcrumbModule,
    MenubarModule,
    TieredMenuModule,
    AvatarModule,
    ButtonModule,
    RippleModule,
    AccordionModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    YearDatepipe,
    ImageUploadComponent,
    ProgressSpinnerModule,
    WaitingDialogComponent,
    ScrollTopModule,
    AnimalImageContainerComponent,
    PaginatorModule,
    TableModule,
    BarcodeScannerComponent
  ]
})
export class SharedModule {
}