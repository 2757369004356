<div class="card">
  <h1 class="m-0 font-semibold">{{ 'animalOverview.headline' | transloco }}</h1>
  <p-divider align="right" [style]="{ margin: 0 }">
    <p-button
      label="{{ 'animalOverview.newAnimal' | transloco }}"
      iconPos="left"
      icon="pi pi-plus"
      class="p-button-rounded"
      (click)="toggleAddDialog()"
      data-cy="open-animal-dialog-btn"></p-button>
  </p-divider>
  <p-dataView
    #dv
    [value]="filteredAnimals"
    [rows]="rowCount"
    [layout]="layout"
    [first]="_animalService.currentPage * rowCount"
    (onChangeLayout)="changeLayout($event)"
    (onPage)="onPageChange($event)"
    [paginator]="true"
  >
    <ng-template pTemplate="header">
      <div class="grid align-items-center">
        <div class="sm:col-12 p-0">
          <app-animal-filters
              (searchEvent)="handleSearch($event)"
          ></app-animal-filters>
        </div>

      </div>
    </ng-template>
    <ng-template let-animal pTemplate="listItem">
      <div class="col-12">
        <div class="flex flex-row align-items-center p-4 gap-4">
          <div class="w-10rem h-10rem relative">
            <app-animal-image-container [preview]="true" [animal]="animal"></app-animal-image-container>
          </div>
          <div class="flex flex-row flex-1 align-items-center">
            <div class="flex flex-1 flex-column">
              <span>{{ animal.displayName }}</span>
              <span>{{ animal.bookNumber}} <span *ngIf="animal.oldBookNumber">({{animal.oldBookNumber}})</span></span>
            </div>
            <div class="flex flex-1">
              <app-animal-current-category [animal]="animal"></app-animal-current-category>
            </div>
            <div class="flex flex-1 flex-column justify-content-center align-items-center">
                <app-animal-sex-icon [animal]="animal"></app-animal-sex-icon>
                <p *ngIf="animal.birthYear">{{ animal.birthYear | date: 'yyyy' }}</p>
                <p *ngIf="animal.birthday">{{ animal.birthday | date: 'dd.MM.yyyy' }}</p>
            </div>
          </div>
          <div
              class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2"
          >
            <p-button
                label="Ansehen"
                iconPos="right"
                class="p-button-rounded"
                [routerLink]="['/tiere', animal.id]"
            ></p-button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template let-animal pTemplate="gridItem">
      <div class="col-12 sm:col-12 lg:col-4 xl:col-4 p-2 cursor-pointer"
           [routerLink]="['/tiere', animal.id]">
        <div class="p-0 border-1 shadow-1 hover:shadow-2 surface-border surface-card border-round">
          <div class="grid">
            <div class="col-12 flex justify-content-center pb-0">
              <div class="flex flex-column w-full h-22rem relative">
                <app-animal-image-container [preview]="true" [animal]="animal"></app-animal-image-container>
              </div>
            </div>
            <div class="col-12 flex align-items-center py-0">
              <div class="col-3 flex justify-content-start">
                <app-animal-current-category [type]="'small'" [animal]="animal"></app-animal-current-category>
              </div>
              <div class="col-6 flex flex-1 justify-content-center">
                <div class="flex flex-column align-items-center">
                  <p class="text-center font-semibold m-0" style="font-size: 20px">
                    {{ animal?.displayName }}
                  </p>
                  <p>{{ animal.bookNumber}} <span *ngIf="animal.oldBookNumber">({{animal.oldBookNumber}})</span></p>
                </div>
              </div>
              <div class="col-3 flex flex-column justify-content-center align-items-center">
                <app-animal-sex-icon [animal]="animal"></app-animal-sex-icon>
                <p *ngIf="animal.birthYear">{{ animal.birthYear | date: 'yyyy' }}</p>
                <p *ngIf="animal.birthday" >{{animal.birthday | date: 'dd.MM.yyyy'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>
</div>
<ng-container *ngIf="addDialogVisible">
  <app-animal-add-dialog></app-animal-add-dialog>
</ng-container>
