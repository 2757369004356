import {NgModule} from "@angular/core";
import {LoginModule} from "./login/login.module";
import {DashboardModule} from "./dashboard/dashboard.module";
import {AnimalsModule} from "./animals/animals.module";
import { ContactModule } from './contact/contact.module';

@NgModule({
  imports: [
    LoginModule,
    DashboardModule,
    AnimalsModule,
    ContactModule
  ],
  exports: [
  ],
})
export class ModulesModule {
}