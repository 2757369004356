import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {AnimalRepository} from "../states/animal.repository";
import {ActivatedRoute} from "@angular/router";
import {Observable, take, takeWhile} from "rxjs";
import {AnimalEditService} from "../services/animal-edit.service";
import {AnimalModel} from "../models/animal.model";
import {GalleriaResponsiveOptions} from 'primeng/galleria';
import {getAnimalHintKey} from "../models/animal-hint.enum";
import {getCharacteristicsEnumKey} from "../models/animal-characteristics.enum";
import {AnimalDetailsTimelineComponent} from '../animal-details-timeline/animal-details-timeline.component';
import {InputSwitchOnChangeEvent} from "primeng/inputswitch";
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-animal-details',
  templateUrl: './animal-details.component.html',
  styleUrls: ['./animal-details.component.scss']
})
export class AnimalDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('animalDetailsTimelineComponent') animalDetailsTimelineComponent!: AnimalDetailsTimelineComponent;
  alive: boolean;
  animal$: Observable<AnimalModel | undefined> = new Observable<AnimalModel | undefined>();
  animal: AnimalModel | undefined;
  responsiveOptions: GalleriaResponsiveOptions[] = [];
  editTabsDialogVisible: { [tab: string]: boolean | string } = {};
  showEditDialog: boolean = false;
  showMoveDialog: boolean = false;
  filteredCoatTypes: string[] | undefined = [];
  animalEditMenuItems: MenuItem[] | undefined;
  motherAnimal: AnimalModel | undefined;

  constructor(
    private _animalRepository: AnimalRepository,
    private _route: ActivatedRoute,
    private _animalEditService: AnimalEditService
  ) {
    this.alive = true;
  }

  ngOnInit(): void {
    this._animalEditService.editDialogVisible.pipe(takeWhile(() => this.alive)).subscribe((openModal) => {
      this.editTabsDialogVisible = openModal;
      this.showEditDialog = openModal && Object.keys(openModal).length ? true : false;
    });

    this._animalEditService.moveDialogVisible.pipe(takeWhile(() => this.alive)).subscribe((openModal) => {
      this.showMoveDialog = openModal;
    });

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 5
      },
      {
        breakpoint: '768px',
        numVisible: 3
      },
      {
        breakpoint: '560px',
        numVisible: 1
      }
    ];

    const idStr = this._route.snapshot.paramMap.get('id');
    if (idStr !== undefined && idStr !== null) {
      const id = parseInt(idStr);
      this.animal$ = this._animalRepository.getById(id);
      this.animal$.pipe(takeWhile(() => this.alive)).subscribe((animal) => {
        this.animal = animal;
        this.filteredCoatTypes = animal?.coatType.filter(x => x !== null && x !== '');
        if (animal?.motherId) {
          this._animalRepository.getById(animal.motherId).pipe(take(1)).subscribe((motherAnimal) => {
            this.motherAnimal = motherAnimal;
          });
        }
      });
    }

    this.animalEditMenuItems = [
      {
        items: [
          {
            label: 'Verschieben',
            icon: 'pi pi-upload',
            command: () => this.toggleMoveDialog()
          }
        ]
      }
    ];
  }

  toggleEditDialog() {
    this._animalEditService.toggleEditDialog({all: true});
  }

  onTabOpenChange(event: any) {
    if (event.index === 3) {
      this.animalDetailsTimelineComponent.loadData();
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.showEditDialog = false;
  }

  protected readonly getAnimalHintKey = getAnimalHintKey;
  protected readonly getCharacteristicsEnumKey = getCharacteristicsEnumKey;

  private toggleMoveDialog() {
    this._animalEditService.toggleMoveDialog();
  }

  onSyncWithWebsiteChange(id: number, $event: InputSwitchOnChangeEvent) {
    this._animalRepository.setSyncWithWebsite(id, $event.checked);
  }

  onSanctuaryChange(id: number, $event: InputSwitchOnChangeEvent) {
    this._animalRepository.setSanctuary(id, $event.checked);
  }

  onFosterHomeWantedChange(id: number, $event: InputSwitchOnChangeEvent) {
    this._animalRepository.setFosterHomeWanted(id, $event.checked);
  }

  onSponsoredChange(id: number, $event: InputSwitchOnChangeEvent) {
    this._animalRepository.setSponsored(id, $event.checked);
  }
}
