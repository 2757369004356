import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {AnimalWhereaboutModel} from '../models/animal-whereabout.model';
import {AnimalModel} from '../models/animal.model';
import {AnimalWhereaboutsRepository} from '../states/animal-whereabouts.repository';
import {AnimaleWhereAboutService} from '../services/animal-whereabout.service';
import {AnimalWhereaboutsType} from '../models/animal-wherabout-types.enum';
import {map, Observable, switchMap, take, takeWhile} from 'rxjs';
import {TranslocoService} from '@ngneat/transloco';
import {PersonTitleModel} from "../../../shared/models/person-title.model";
import {ContactRepository} from "../../contact/states/contact.repository";
import {ContactModel} from "../../contact/models/contact.model";
import {HelperService} from "../../../shared/services/helper.service";
import {AnimalRepository} from "../states/animal.repository";
import { ConfirmationService } from 'primeng/api';

export interface NewAnimalWhereaboutItem {
  id: string;
  animal: { id: number };
  type: string | null;
  date: Date | null;
  contact: { id: number | null };
  dateCare: Date | null;
  information: string | null;
  stillOnWebsite: boolean;
}

@Component({
  selector: 'app-animal-details-whereabouts',
  templateUrl: './animal-details-whereabouts.component.html',
  styleUrls: ['./animal-details-whereabouts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConfirmationService]
})
export class AnimalDetailsWhereaboutsComponent implements OnInit {
  @Input() animal: AnimalModel | undefined;
  @Output() formsValid: EventEmitter<boolean> = new EventEmitter();
  alive: boolean = true;
  isEditingMode: boolean = false;
  isAddMode: boolean = false;
  animalWhereaboutsItems: { [id: string]: AnimalWhereaboutModel | any} = {};
  whereAboutTypeOptions: { value: string, name: string }[] = [];
  selectedTypeOption: { [id: string]: { value: string, name: string } } = {};
  editingWhereAboutKey: string = '';
  personSalutationOptions: PersonTitleModel[] = [];
  
  contacts: { [id: string]: ContactModel } = {};
  editingItem: any;
  openEdit: boolean = false;
  editType: any;
  editDialogContactKey: any;
  editDialogWhereAboutKey: any;

  contact: ContactModel = {} as ContactModel;
  existingContact: boolean = false;
  existingContactUpdated: boolean = false;
  validationStatusChanged: boolean = true;

  constructor(
    private _animalWhereaboutsRepository: AnimalWhereaboutsRepository,
    private _contactRepository: ContactRepository,
    private _animalRepository: AnimalRepository,
    private _translocoService: TranslocoService,
    private _animalWhereaboutService: AnimaleWhereAboutService,
    private _helperService: HelperService,
    private cdr: ChangeDetectorRef,
    private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit(): void {
    this._animalWhereaboutsRepository.getByAnimalId(this.animal?.id).pipe(takeWhile(() => this.alive)).subscribe((animalWhereabouts: AnimalWhereaboutModel) => {
      if (Object.keys(animalWhereabouts).length) {
        this.setAnimalWhereabouts(animalWhereabouts);
      }
    });

    this.whereAboutTypeOptions = this._animalWhereaboutService.getAnimalWhereaboutTypeOptions();

    this.personSalutationOptions = this._helperService.getSalutationOptions();
  }

  setAnimalWhereabouts(animalWhereabouts: AnimalWhereaboutModel ) {
    Object.values(animalWhereabouts).forEach((animalWhereabout: AnimalWhereaboutModel) => {
      if (!animalWhereabout.id) {
        return;
      }
      this._contactRepository.getById(animalWhereabout.contact?.id).pipe(take(1)).subscribe((contact: ContactModel) => {
        this.animalWhereaboutsItems[animalWhereabout.id] = JSON.parse(JSON.stringify(animalWhereabout));
        this.contacts[animalWhereabout.contact?.id] = contact;
        if (this.animalWhereaboutsItems[animalWhereabout.id].date) {
          const dateValue = this.animalWhereaboutsItems[animalWhereabout.id].date;
          const formattedDate = dateValue ? new Date(dateValue) : null;
          this.animalWhereaboutsItems[animalWhereabout.id].date = formattedDate;
        }
        if (this.animalWhereaboutsItems[animalWhereabout.id].dateCare) {
          const dateCareValue = this.animalWhereaboutsItems[animalWhereabout.id].dateCare;
          const formattedDateCare = dateCareValue ? new Date(dateCareValue) : null;
          this.animalWhereaboutsItems[animalWhereabout.id].dateCare = formattedDateCare;
        }

        Promise.resolve().then(() => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          const foundOption = this.whereAboutTypeOptions.find(option => option.value === animalWhereabout.type);
          if (foundOption) {
            this.selectedTypeOption[animalWhereabout.id] = foundOption;
          }

          this.cdr.detectChanges();
        });
      });
    });
    this.cdr.detectChanges();
  }

  toggleEditDialog(open: boolean, editType: string, whereAboutKey: string, contactKey?: number | null) {
    if (open) {
      if (editType === 'general') {
        this.editingItem = JSON.parse(JSON.stringify(this.animalWhereaboutsItems[whereAboutKey]));
        this.editingItem.date = this.editingItem.date 
        ? new Date(this.editingItem.date) 
        : null;
        this.editingItem.dateCare = this.editingItem.dateCare 
        ? new Date(this.editingItem.dateCare) 
        : null;
      } else if (editType === 'givingContact') {
        if (contactKey && this.contacts[contactKey]) {
          this.editingItem = JSON.parse(JSON.stringify(this.contacts[contactKey]));
        } else {
          this.editingItem = {} as ContactModel;
        }
        this.existingContact = Object.values(this.editingItem).some(value => value !== null && value !== undefined);
      }
      this.editType = editType;
      this.editDialogContactKey = contactKey;
      this.editDialogWhereAboutKey = whereAboutKey;
      this.isEditingMode = true;

      this.openEdit = open
      
    } else {
      this.openEdit = open;
      this.editDialogContactKey = '';
      this.editDialogWhereAboutKey = '';
      this.editingItem = null;
      this.isEditingMode = false;
    }
  }

  onContactUpdate($event: any) {
    this.contact = $event;
  }
  onValidationStatusChanged($event: boolean) {
    this.validationStatusChanged = $event;
  }
  onExistingContactUpdated($event: boolean) {
    this.existingContactUpdated = $event;
  }
  onExistingContactSelected($event: ContactModel) {
    this.contact = $event;
    this.existingContact = Object.keys($event).length ? true : false;
  }

  addNewWhereabout(): void {
    if (!this.animal) {
      return;
    }

    const emptyWhereabout: NewAnimalWhereaboutItem = {
      id: 'dummy',
      animal: {id: this.animal?.id} as any,
      type: null,
      date: null,
      contact: {id: null},
      dateCare: null,
      information: null,
      stillOnWebsite: this.animal.syncWithWebsite
    };

    this.editingItem = {} as ContactModel;
    (this.animalWhereaboutsItems['dummy'] as NewAnimalWhereaboutItem) = emptyWhereabout;
    this.isAddMode = true;
    this.cdr.detectChanges();
  }

  cancelNewWhereabout() {
    delete this.animalWhereaboutsItems['dummy'];
    this.isAddMode = false;
    this.editingItem = null;
    this.contact = {} as ContactModel;
    this.cdr.detectChanges
  }

  saveNewWhereabout() {

    let date = null;
    if (this.animalWhereaboutsItems['dummy'].date) {
      date = this._helperService.determineDateOfReceipt(
        this.animalWhereaboutsItems['dummy'].date,
        this.animalWhereaboutsItems['dummy'].date
      );
    }

    let dateCare = null;
    if (this.animalWhereaboutsItems['dummy'].dateCare) {
      dateCare = this._helperService.determineDateOfReceipt(
        this.animalWhereaboutsItems['dummy'].dateCare,
        this.animalWhereaboutsItems['dummy'].dateCare
      );
    }

    const payload: any = {
      type: this.selectedTypeOption['dummy'].value,
      date: date,
      dateCare: dateCare,
      information: this.animalWhereaboutsItems['dummy'].information,
      animal: {id: this.animal?.id} as any,
    };
    this.updateAnimal((this.animalWhereaboutsItems['dummy'] as NewAnimalWhereaboutItem).stillOnWebsite);
    if (this.contact && this.contact.id && !this.existingContactUpdated) {
      payload.contact = {id: this.contact.id};
      this._animalWhereaboutsRepository.create(payload).pipe(take(1)).subscribe((response) => {
        this.cancelNewWhereabout();
        this.handleResponse(response);
        this.contacts[this.contact.id] = this.contact;
        this.cdr.detectChanges();
      });
    } else if (this.contact && this.contact.id && this.existingContactUpdated) {
      this._contactRepository.update(this.contact, this.contact.id).pipe(
        switchMap((response) => {
          payload.contact = {id: this.contact.id};
          this.contacts[response.id] = response;
          return this._animalWhereaboutsRepository.create(
            payload
          ).pipe(
            map((response) => {
              this.handleResponse(response);
              this.cancelNewWhereabout();
          })
        );
        }),
        take(1)
      );
    } else if (Object.keys(this.contact).length && !this.contact.id) {
      this._contactRepository.create(this.contact).pipe(
        switchMap((response) => {
          payload.contact = {id: response.id};
          this.contacts[response.id] = response;
          return this._animalWhereaboutsRepository.create(
            payload
          ).pipe(
            map((response) => {
              this.handleResponse(response);
              this.cancelNewWhereabout();
          })
        );
        }),
        take(1)
      ).subscribe(() => {
        this.cdr.detectChanges();
      });
    } else {
      this._animalWhereaboutsRepository.create(payload).pipe(take(1)).subscribe((response) => {
        this.cancelNewWhereabout();
        this.handleResponse(response);
      });
    }
  }

  handleResponse(response: any) {
    response.date = response.date 
    ? new Date(response.date) 
    : null;
    response.dateCare = response.dateCare 
    ? new Date(response.dateCare) 
    : null;
    this.animalWhereaboutsItems = {
      ...this.animalWhereaboutsItems,
      [response.id]: response
    };
    const foundOption = this.whereAboutTypeOptions.find(option => option.value === response.type);
    if (foundOption) {
    this.selectedTypeOption[response.id] = foundOption;
    }
    this.cdr.detectChanges();
  }

  updateAnimal(stillOnWebsite: boolean) {
    if (!this.animal || !this.animal.id) {
      return;
    }

    this._animalRepository.update(this.animal.id, {
      syncWithWebsite: stillOnWebsite
    });
  }


  dummyFirst = (a: any, b: any) => {
    return a.key === 'dummy' ? -1 : b.key === 'dummy' ? 1 : 0;
  }


  private updateContactAndWhereAbout(
    contactPayload: ContactModel,
    whereAboutPayload: AnimalWhereaboutModel
  ): Observable<void> {
    return this._contactRepository.update(contactPayload, contactPayload.id).pipe(
      switchMap((response) => {
        whereAboutPayload.contact = {id: contactPayload.id};
        this.contacts[response.id] = response;
        return this._animalWhereaboutsRepository.update(
          whereAboutPayload.id.toString(),
          whereAboutPayload
        ).pipe(
          map((response) => {
            response.date = response.date 
              ? new Date(response.date) 
              : null;
              response.dateCare = response.dateCare 
              ? new Date(response.dateCare) 
              : null;
            this.animalWhereaboutsItems = {
              ...this.animalWhereaboutsItems,
              [whereAboutPayload.id]: response
            };
        })
      );
      }),
      take(1)
    );
  }
  

  private createContactAndUpdateWhereAbout(
    contactPayload: ContactModel,
    whereAboutPayload: AnimalWhereaboutModel,
    key: string,
    editingType: string
  ): Observable<void> {
    return this._contactRepository.create(contactPayload).pipe(
      map(response => {
        const updatedPayload = { ...whereAboutPayload };
  
      if (editingType === 'givingContact') {
          updatedPayload.contact = { id: response.id };
        }
        this.contacts[response.id] = response;
        return updatedPayload;
      }),
      switchMap(updatedPayload =>
        this._animalWhereaboutsRepository.update(key, updatedPayload).pipe(
          map((response) => {
            response.date = response.date 
              ? new Date(response.date) 
              : null;
              response.dateCare = response.dateCare 
              ? new Date(response.dateCare) 
              : null;
            this.animalWhereaboutsItems = {
              ...this.animalWhereaboutsItems,
              [key]: response
            };
          })
        )
      ),
      take(1)
    );
  }
  

  save(animalWhereAboutItem: AnimalWhereaboutModel) {
    if (this.editType === 'general') {
      this.updateAnimalWhereAbout(animalWhereAboutItem);
    } else if (this.editType === 'givingContact' || this.editType === 'owningContact') {
      if (this.existingContact) {
        this.updateContactAndWhereAbout(
          this.contact,
          this.animalWhereaboutsItems[this.editDialogWhereAboutKey]
        ).subscribe(() => {

          this.animalWhereaboutsItems[this.editDialogWhereAboutKey].contact = { id: this.contact.id };
          this.toggleEditDialog(false, '', '');
          this.cdr.detectChanges();
        });
      } else if (this.contact) {
        this.createContactAndUpdateWhereAbout(
          this.contact,
          animalWhereAboutItem,
          this.editDialogWhereAboutKey,
          this.editType
        ).subscribe(() => {
          this.toggleEditDialog(false, '', '');
          this.cdr.detectChanges();
        });
      }
    }

  }

  updateAnimalWhereAbout(animalWhereAboutItem: AnimalWhereaboutModel | any): void {
    if (!this.animal || !this.animal.id) {
      return;
    }
    const date = this._helperService.determineDateOfReceipt(
      this.animalWhereaboutsItems[this.editDialogWhereAboutKey].date,
      animalWhereAboutItem.date
    );
    const dateCare = this._helperService.determineDateOfReceipt(
      this.animalWhereaboutsItems[this.editDialogWhereAboutKey].date,
      animalWhereAboutItem.dateCare
    );
  
    const whereAboutPayload: AnimalWhereaboutModel = {
      ...animalWhereAboutItem,
      date,
      dateCare,
      animal: { id: this.animal.id },
      contact: animalWhereAboutItem.contact && animalWhereAboutItem.contact.id ? { id: animalWhereAboutItem.contact.id } : null,
      type: animalWhereAboutItem.type,
      information: animalWhereAboutItem.information
    };
  

  
    this._animalWhereaboutsRepository.update(this.editDialogWhereAboutKey, whereAboutPayload)
      .pipe(take(1))
      .subscribe(() => {
        this.animalWhereaboutsItems = {
          ...this.animalWhereaboutsItems,
          [this.editDialogWhereAboutKey]: whereAboutPayload
        };

        this.toggleEditDialog(false, '', '');
        this.cdr.detectChanges();

      });
  }
  

  dummyTranslateFunction() {
    this._translocoService.translate('animalWhereabout.died');
    this._translocoService.translate('animalWhereabout.adopted');
    this._translocoService.translate('animalWhereabout.back-with-owner');
    this._translocoService.translate('animalWhereabout.passed-on');
    this._translocoService.translate('animalWhereabout.ran-away');
    this._translocoService.translate('animalWhereabout.released-into-wild');
    this._translocoService.translate('animalWhereabout.adopted-on-trial');
    this._translocoService.translate('animalWhereabout.outdoor-animal');
    this._translocoService.translate('animalWhereabout.foster-home');
    this._translocoService.translate('animalWhereabout.euthanasia');
    this._translocoService.translate('animalWhereabout.conveyed');
  }

  deleteWhereabout(id: string) {
    this.confirmationService.confirm({
      message: this._translocoService.translate('delete_confirmation'),
      accept: () => {
        this._animalWhereaboutsRepository.delete(id).pipe(take(1)).subscribe(() => {
          delete this.animalWhereaboutsItems[id];
          this.cdr.detectChanges();
        });
      }
    });
  }

  closeEditModal() {
    throw new Error('Method not implemented.');
  }

}
