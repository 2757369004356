import {Injectable} from "@angular/core";
import { AnimalImageCategoryModel } from '../models/animal-image-category.model';
import { HelperService } from 'src/app/shared/services/helper.service';
import { AnimalImageCategory } from '../models/animal-image-category.enum';

@Injectable({
  providedIn: 'root'
})
export class AnimalImageService {

  constructor(
    private _helperService: HelperService
  ) {
  }

  getAnimalImageCategories(): AnimalImageCategoryModel[] {
    return this._helperService.getEnumOptions(AnimalImageCategory, 'animal.imageCategory', true);
  }

}