import {NgModule} from '@angular/core';
import { ChooseContactComponent } from './components/choose-contact/choose-contact.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [ChooseContactComponent],
  imports: [SharedModule],
  exports: [ChooseContactComponent],
})
export class ContactModule {
}
