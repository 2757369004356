<p-dialog
  [(visible)]="moveDialogVisible"
  [style]="{ width: '50vw', height: '90vh' }"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="closeModal()"
>
  <ng-template pTemplate="closeicon">
    <span class="closeicon pi pi-times"></span>
  </ng-template>
  <ng-template pTemplate="header">
    {{ 'animalEdit.move.headline' | transloco }}
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex flex-column">
      <p-messages severity="info">
        <ng-template pTemplate>
          <i class="pi pi-info"></i>
          <div class="ml-2">
            <p>{{ 'animalEdit.move.info' | transloco }}</p>
            <p>{{ 'animalEdit.move.consequences' | transloco }}</p>
            <ul>
              <li>{{ 'animalEdit.move.consequencesList.animal' | transloco }}</li>
              <li>{{ 'animalEdit.move.consequencesList.animalBookNumber' | transloco }}</li>
              <li>{{ 'animalEdit.move.consequencesList.animalOrigin' | transloco }}</li>
              <li>{{ 'animalEdit.move.consequencesList.animalWhereabout' | transloco }}</li>
            </ul>
          </div>
        </ng-template>
      </p-messages>
      <form [formGroup]="formGroup">
      <p-dropdown
        [options]="shelters"
        [(ngModel)]="selectedShelter"
        [style]="{ width: '100%' }"
        [filter]="true"
        [showClear]="false"
        [optionLabel]="'title'"
        [dataKey]="'id'"
        formControlName="animalShelter"
        appendTo="body"
      ></p-dropdown>
      </form>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex">
      <div class="flex-1 text-center">
        <button class="p-button p-button-success" (click)="saveChanges()" [disabled]="formGroup.invalid">
          {{ 'animalEdit.general.saveChanges' | transloco }}
        </button>
      </div>
    </div>
    <div class="flex-end">
      <span class="required">* {{ 'animalEdit.general.required' | transloco }}</span>
    </div>
  </ng-template>
</p-dialog>
